import Vue from 'vue';

import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'account',
    plural: 'accounts'
  }),
  ...actionFactory(
    {
      singular: 'account',
      plural: 'accounts'
    },
    {
      singular: 'credit-note',
      plural: 'credit-notes'
    },
    {
      fileActions: true
    }
  ),
  convertInvoiceToCreditNote: async ({ commit }, { id, invoiceId }) => {
    try {
      commit('CONVERT_INVOICE_TO_CREDIT_NOTE_REQUEST');
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/accounts/${id}/invoices/${invoiceId}/to-credit-note`);
      commit('CONVERT_INVOICE_TO_CREDIT_NOTE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('CONVERT_INVOICE_TO_CREDIT_NOTE_ERROR', e);
      return e;
    }
  },
  convertCreditNoteToInvoice: async ({ commit }, { id, creditNoteId }) => {
    try {
      commit('CONVERT_CREDIT_NOTE_TO_INVOICE_REQUEST', creditNoteId);
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/accounts/${id}/credit-notes/${creditNoteId}/to-invoice`);
      commit('CONVERT_CREDIT_NOTE_TO_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('CONVERT_CREDIT_NOTE_TO_INVOICE_ERROR', e);
      return e;
    }
  },
  stampCreditNote: async (action, { id, creditNoteId }) => {
    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/${id}/credit-notes/${creditNoteId}/stamp`);
      return body;
    } catch (e) {
      return e;
    }
  },
  downloadCreditNote: async ({ commit }, { id, creditNoteId }) => {
    try {
      commit('DOWNLOAD_CREDIT_NOTE_REQUEST', creditNoteId);
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/${id}/credit-notes/${creditNoteId}/download`);
      commit('DOWNLOAD_CREDIT_NOTE_SUCCESS', body);
      return body;
    } catch (e) {
      return e;
    }
  },
  search: async ({ commit }, { data }) => {
    // const qs = [];

    // qs.push(`query=${query}`);

    // Object.keys(params || {}).forEach(option => {
    //   qs.push(`${option}=${params[option]}`);
    // });

    // if (entityId) qs.push(`entityId=${entityId}`);
    // if (companyId) qs.push(`companyId=${companyId}`);

    console.log('data', data);

    try {
      commit('SEARCH_ACCOUNTS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/search`, data);
      commit('SEARCH_ACCOUNTS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('SEARCH_ACCOUNTS_ERROR', e);
      return e;
    }
  },
  uploadInvoice: async ({ commit }, { id, data }) => {
    try {
      commit('UPLOAD_ACCOUNT_INVOICE_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/${id}/invoice`, data);
      commit('UPLOAD_ACCOUNT_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_ACCOUNT_INVOICE_ERROR', e);
      return e;
    }
  },
  processingInvoices: async ({ commit }, { id }) => {
    try {
      commit('ACCOUNT_JOB_PROGRESS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/${id}?$select=processingInvoices`);
      commit('ACCOUNT_JOB_PROGRESS_SUCCESS', body.processingInvoices);
      return body.processingInvoices;
    } catch (e) {
      commit('ACCOUNT_JOB_PROGRESS_ERROR', e);
      return e;
    }
  },
  getInvoiceUrl: async ({ commit }, { id, invoiceId }) => {
    try {
      commit('ACCOUNT_INVOICE_URL_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/${id}/invoice/${invoiceId}/download`);
      // commit('ACCOUNT_INVOICE_URL_SUCCESS', body.url);
      return body.url;
    } catch (e) {
      commit('ACCOUNT_INVOICE_URL_ERROR', e);
      return e;
    }
  },
  deleteInvoice: async ({ commit }, { id, invoiceId }) => {
    try {
      commit('DELETE_ACCOUNT_INVOICE_REQUEST');
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/accounts/${id}/invoice/${invoiceId}`);
      commit('DELETE_ACCOUNT_INVOICE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_ACCOUNT_INVOICE_ERROR', e);
      return e;
    }
  },
  addContract: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_CONTRACT_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/${id}/contracts`, data);
      commit('ADD_CONTRACT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('ADD_CONTRACT_ERROR', {
        type: 'submit',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);

      return e;
    }
  },
  updateContract: async ({ commit }, { id, contractId, data }) => {
    try {
      delete data._id;

      commit('UPDATE_CONTRACT_REQUEST', { id, contractId, data });
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/accounts/${id}/contracts/${contractId}`, data);
      commit('UPDATE_CONTRACT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_CONTRACT_ERROR', {
        type: 'submit',
        message: 'There was a problem with submitting.',
        data: e.body.message
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);
      return e;
    }
  },
  deleteContract: async ({ commit }, { id, contractId }) => {
    try {
      commit('DELETE_CONTRACT_REQUEST', { contractId });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/accounts/${id}/contracts/${contractId}`);
      commit('DELETE_CONTRACT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_CONTRACT_ERROR', e);
      return e;
    }
  },
  downloadContract: async ({ commit }, { id, contractId, name }) => {
    try {
      commit('DOWNLOAD_CONTRACT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/${id}/contracts/${contractId}/download?name=${name}`);
      commit('DOWNLOAD_CONTRACT_SUCCESS', body.url);
      return body.url;
    } catch (e) {
      commit('DOWNLOAD_CONTRACT_ERROR', e);
      return e;
    }
  },
  toggleContractStatus: async ({ commit }, { id, contractId }) => {
    try {
      commit('TOGGLE_CONTRACT_STATUS_REQUEST');
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/accounts/${id}/contracts/${contractId}/toggle-status`);
      commit('TOGGLE_CONTRACT_STATUS_SUCCESS');
      return body;
    } catch (e) {
      commit('TOGGLE_CONTRACT_STATUS_ERROR', {
        type: 'submit',
        message: 'There was a problem updating the status.',
        data: e.body.message
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);
      return e;
    }
  },
  getReadings: async ({ commit }, { data }) => {
    try {
      commit('GET_READINGS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/readings`, data);
      commit('GET_READINGS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_READINGS_ERROR', e);
      return e;
    }
  },
  updateReading: async ({ commit }, { id, data }) => {
    try {
      delete data._id;

      commit('UPDATE_READING_REQUEST', { id, data });
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/readings/${id}`, data);
      commit('UPDATE_READING_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_READING_ERROR', {
        type: 'submit',
        message: 'There was a problem with submitting.',
        data: e.body
      });

      commit('SET_VALIDATION_ERRORS', e.body.formMessages);
      return e;
    }
  },
  deleteReading: async ({ commit }, { id }) => {
    try {
      commit('DELETE_READING_REQUEST', { id });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/readings/${id}`);
      commit('DELETE_READING_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_READING_ERROR', e);
      return e;
    }
  },
  getReadingPhoto: async ({ commit }, { id, data }) => {
    try {
      commit('GET_READING_PHOTO_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/readings/${id}/photo`, data);
      commit('GET_READING_PHOTO_SUCCESS', { url: body.url, id });
      return body;
    } catch (e) {
      commit('GET_READING_PHOTO_ERROR', e.body.message || e.message);
      return e;
    }
  },
  uploadCsv: async ({ commit }, { data }) => {
    try {
      commit('UPLOAD_CSV_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/upload-csv`, data, {
        progress(e) {
          commit('UPLOAD_CSV_PROGRESS', (e.loaded / e.total) * 100);
        }
      });
      commit('UPLOAD_CSV_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CSV_ERROR', e);
      return e;
    }
  },
  uploadCsvRollback: async ({ commit }, { batchId }) => {
    try {
      commit('UPLOAD_CSV_ROLLBACK_REQUEST');
      const { body } = await Vue.http.post(
        `${process.env.VUE_APP_API_URL}/accounts/import-rollback`,
        { batchId },
        {
          progress(e) {
            commit('UPLOAD_CSV_ROLLBACK_PROGRESS', (e.loaded / e.total) * 100);
          }
        }
      );
      commit('UPLOAD_CSV_ROLLBACK_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CSV_ROLLBACK_ERROR', e);
      return e;
    }
  },
  uploadContractCsv: async ({ commit }, { data, params }) => {
    const qs = [];

    Object.keys(params).forEach(option => {
      qs.push(`${option}=${params[option]}`);
    });

    try {
      commit('UPLOAD_CONTRACT_CSV_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/upload-contract-csv?${qs.join('&')}`, data, {
        progress(e) {
          commit('UPLOAD_CONTRACT_CSV_PROGRESS', (e.loaded / e.total) * 100);
        }
      });
      commit('UPLOAD_CONTRACT_CSV_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CONTRACT_CSV_ERROR', e);
      return e;
    }
  },
  uploadContractPdf: async ({ commit }, { data, id }) => {
    try {
      commit('UPLOAD_CONTRACT_PDF_REQUEST');

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/${id}/contracts/upload`, data);

      commit('UPLOAD_CONTRACT_PDF_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CONTRACT_PDF_ERROR', e);
      return e;
    }
  },
  uploadContractCsvRollback: async ({ commit }, { batchId }) => {
    try {
      commit('UPLOAD_CONTRACT_CSV_ROLLBACK_REQUEST');
      const { body } = await Vue.http.post(
        `${process.env.VUE_APP_API_URL}/accounts/contract-import-rollback`,
        { batchId },
        {
          progress(e) {
            commit('UPLOAD_CONTRACT_CSV_ROLLBACK_PROGRESS', (e.loaded / e.total) * 100);
          }
        }
      );
      commit('UPLOAD_CONTRACT_CSV_ROLLBACK_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPLOAD_CONTRACT_CSV_ROLLBACK_ERROR', e);
      return e;
    }
  },
  addStatusHistory: async ({ commit }, { id, data }) => {
    try {
      commit('ADD_STATUS_HISTORY_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/${id}/status-history`, data);
      commit('ADD_STATUS_HISTORY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('ADD_STATUS_HISTORY_ERROR', e);
      return e;
    }
  },
  updateStatusHistory: async ({ commit }, { id, statusId, data }) => {
    try {
      delete data._id;

      commit('UPDATE_STATUS_HISTORY_REQUEST', { id, statusId, data });
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/accounts/${id}/status-history/${statusId}`, data);
      commit('UPDATE_STATUS_HISTORY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('UPDATE_STATUS_HISTORY_ERROR', e);
      return e;
    }
  },
  deleteStatusHistory: async ({ commit }, { id, statusId }) => {
    try {
      commit('DELETE_STATUS_HISTORY_REQUEST', { id, statusId });
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_API_URL}/accounts/${id}/status-history/${statusId}`);
      commit('DELETE_STATUS_HISTORY_SUCCESS', body);
      return body;
    } catch (e) {
      commit('DELETE_STATUS_HISTORY_ERROR', e);
      return e;
    }
  },
  getConsumptionOld: async ({ commit }, { data, id }) => {
    try {
      commit('GET_CONSUMPTION_OLD_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/${id}/consumption-old`, data);
      commit('GET_CONSUMPTION_OLD_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_CONSUMPTION_OLD_ERROR', e);
      return e;
    }
  },
  getConsumptions: async ({ commit }, { data, accountId }) => {
    try {
      commit('GET_CONSUMPTIONS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/${accountId}/consumption`, data);
      commit('GET_CONSUMPTIONS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_CONSUMPTIONS_ERROR', e);
      return e;
    }
  },
  uploadConsumption: async ({ commit }, { data, file }) => {
    try {
      commit('UPLOAD_CONSUMPTION_REQUEST');

      const qs = [];

      Object.keys(data).forEach(option => {
        qs.push(`${option}=${data[option]}`);
      });

      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/consumptions/upload?${qs.join('&')}`, file.file);

      commit('UPLOAD_CONSUMPTION_SUCCESS', body);

      return body;
    } catch (e) {
      commit('UPLOAD_CONSUMPTION_ERROR', e.body.message || e.message);
      return e;
    }
  },
  importConsumption: async ({ commit }, { id, data }) => {
    try {
      commit('IMPORT_CONSUMPTIONS_REQUEST', { id });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/accounts/${id}/consumption-import`, data);
      commit('IMPORT_CONSUMPTIONS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('IMPORT_CONSUMPTIONS_ERROR', e);
      return e;
    }
  },
  getWasteCategories: async ({ commit }) => {
    try {
      commit('GET_WASTE_CATEGORIES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/waste-categories`);
      commit('GET_WASTE_CATEGORIES_SUCCESS', body);
      return body.url;
    } catch (e) {
      commit('GET_WASTE_CATEGORIES_ERROR', e);
      return e;
    }
  },
  getTags: async ({ commit }) => {
    try {
      commit('GET_TAGS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/accounts/tags`);
      commit('GET_TAGS_SUCCESS', body);
      return body.url;
    } catch (e) {
      commit('GET_TAGS_ERROR', e);
      return e;
    }
  }
};
