var render = function () {
  var _vm$error$floorArea;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-3 col-lg-4 col-md-5 col-12"
  }, [_c('h4', [_vm._v("Manage")]), _c('VerticalPills', {
    attrs: {
      "items": _vm.categories,
      "item-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "nav-link",
          class: {
            active: _vm.selectedCategoryId === item.id
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSelectCategory(item);
            }
          }
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_c('i', {
          staticClass: "fa fa-fw mr-1 text-primary",
          class: "".concat(item.icon)
        }), _vm._v(" " + _vm._s(item.title))])])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": _vm.selectedCategory.icon
    }
  }, [_vm._v(_vm._s(_vm.selectedCategory.title))]), _c('div', {
    staticClass: "row"
  }, [_vm.loading ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('SpinnerLogo')], 1) : _vm.selectedCategoryId === 'general' ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "siteName",
      "disabled": _vm.disabled,
      "error": _vm.error['siteName'],
      "label": "Site Name",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.siteName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "siteName", $$v);
      },
      expression: "form.siteName"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "siteCode",
      "disabled": _vm.disabled,
      "error": _vm.error['siteCode'],
      "label": "Site Code",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.siteCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "siteCode", $$v);
      },
      expression: "form.siteCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "assetType",
      "error": _vm.error['assetType'],
      "label": "Asset Type",
      "type": "select2",
      "options": _vm.assetTypeOptions
    },
    model: {
      value: _vm.form.assetType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assetType", $$v);
      },
      expression: "form.assetType"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "dm-profile-edit-name"
    }
  }, [_vm._v("Currency")]), _c('CurrencySelect', {
    staticClass: "form-control",
    attrs: {
      "id": "currency",
      "type": "text",
      "name": "currency",
      "placeholder": "Select a Currency..."
    },
    model: {
      value: _vm.form.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1), _vm.entityOptions.length ? _c('FormGroup', {
    attrs: {
      "id": "entityId",
      "error": _vm.error['entityId'],
      "options": _vm.entityOptions,
      "type": "select2",
      "config": {
        allowClear: false
      },
      "label": "Entity"
    },
    model: {
      value: _vm.form.entityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "entityId", $$v);
      },
      expression: "form.entityId"
    }
  }) : _vm._e()], 1) : _vm.selectedCategoryId === 'images' ? _c('div', {
    staticClass: "col-xl-10"
  }, [_c('div', {
    staticClass: "border-bottom mb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block mb-4 mt-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.modals.uploadImage = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-1"
  }), _vm._v(" Upload new image ")]), _vm._m(0), _c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("Below are images of this asset that have been uploaded by users.")]), _vm._l(_vm.form.images.filter(function (i) {
    return i.type === 'upload';
  }), function (img) {
    return _c('div', {
      key: img._id,
      staticClass: "row pb-5 mb-5"
    }, [_c('div', {
      staticClass: "col-xl-6 col-lg-6 mb-lg-0 mb-4"
    }, [_c('a', {
      attrs: {
        "href": img.url,
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "img-fluid AssetForm-image",
      attrs: {
        "src": img.url
      }
    })])]), _c('div', {
      staticClass: "col"
    }, [_c('FormGroup', {
      attrs: {
        "id": "img-".concat(img._id),
        "label": "Label",
        "type": "text"
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('input', _vm.form);
        }
      },
      model: {
        value: img.label,
        callback: function callback($$v) {
          _vm.$set(img, "label", $$v);
        },
        expression: "img.label"
      }
    }), _c('FormGroup', {
      staticClass: "mb-3",
      attrs: {
        "id": "img-isPrimary-".concat(img._id),
        "description-position": "top",
        "description": "The primary image is the main image associated with this asset. It'll be used on the Overview tab, reports etc.",
        "type": "radio",
        "label": "Use as primary?",
        "options": [{
          label: 'No',
          value: false
        }, {
          label: 'Yes',
          value: true
        }]
      },
      on: {
        "input": function input($event) {
          return _vm.onSetPrimaryImage(img);
        }
      },
      model: {
        value: img.isPrimary,
        callback: function callback($$v) {
          _vm.$set(img, "isPrimary", $$v);
        },
        expression: "img.isPrimary"
      }
    }), _c('FormGroup', {
      staticClass: "mb-3",
      attrs: {
        "id": "img-isHidden-".concat(img._id),
        "description-position": "top",
        "description": "Some reports display all images associated with an asset. Select 'Yes' to hide this image from those reports.",
        "type": "radio",
        "label": "Hide on reports?",
        "options": [{
          label: 'No',
          value: false
        }, {
          label: 'Yes',
          value: true
        }]
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('input', _vm.form);
        }
      },
      model: {
        value: img.isHidden,
        callback: function callback($$v) {
          _vm.$set(img, "isHidden", $$v);
        },
        expression: "img.isHidden"
      }
    }), _c('div', {
      staticClass: "text-right"
    }, [_c('button', {
      staticClass: "btn btn-sm btn-primary text-nowrap mr-2",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('save', true);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-save mr-1"
    }), _vm._v(" Save ")]), img.type === 'upload' ? _c('button', {
      staticClass: "btn btn-sm btn-danger text-nowrap",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickDeleteImage(img);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash mr-1"
    }), _vm._v(" Delete ")]) : _vm._e()])], 1)]);
  }), _vm.form.images.filter(function (i) {
    return i.type === 'upload';
  }).length === 0 ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No uploaded images.")]) : _vm._e()], 2), _c('div', {
    staticClass: "row"
  }, [_vm._m(1), _vm.id ? _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "bg-lightest p-3 rounded-md"
  }, [_c('label', {
    staticClass: "text-gray-dark font-w600 font-size-sm mb-1"
  }, [_vm._v("Updated the address? Click below to fetch new images.")]), _c('button', {
    staticClass: "btn btn-info btn-sm btn-block mr-2",
    attrs: {
      "disabled": _vm.loadingAction.updateSatelliteImages
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickFetchImages($event);
      }
    }
  }, [_vm.loadingAction.updateSatelliteImages ? _c('span', [_c('i', {
    staticClass: "fa fa-spinner fa-spin mr-1"
  }), _vm._v(" Fetching Images... ")]) : _c('span', [_c('i', {
    staticClass: "fa fa-images mr-2"
  }), _vm._v("Re-fetch images")])])])]) : _vm._e()]), _vm._l(_vm.form.images.filter(function (i) {
    return i.type !== 'upload';
  }), function (img) {
    return _c('div', {
      key: img._id,
      staticClass: "row pb-5 mb-5 border-bottom"
    }, [_c('div', {
      staticClass: "col-xl-6 col-lg-6 mb-lg-0 mb-4"
    }, [_c('a', {
      attrs: {
        "href": img.url,
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "img-fluid AssetForm-image",
      attrs: {
        "src": img.url
      }
    })])]), _c('div', {
      staticClass: "col"
    }, [_c('FormGroup', {
      attrs: {
        "id": "img-".concat(img._id),
        "label": "Label",
        "type": "text"
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('input', _vm.form);
        }
      },
      model: {
        value: img.label,
        callback: function callback($$v) {
          _vm.$set(img, "label", $$v);
        },
        expression: "img.label"
      }
    }), _c('FormGroup', {
      staticClass: "mb-3",
      attrs: {
        "id": "img-isPrimary-".concat(img._id),
        "description-position": "top",
        "description": "The primary image is the main image associated with this asset. It'll be used on the Overview tab, reports etc.",
        "type": "radio",
        "label": "Use as primary?",
        "options": [{
          label: 'No',
          value: false
        }, {
          label: 'Yes',
          value: true
        }]
      },
      on: {
        "input": function input($event) {
          return _vm.onSetPrimaryImage(img);
        }
      },
      model: {
        value: img.isPrimary,
        callback: function callback($$v) {
          _vm.$set(img, "isPrimary", $$v);
        },
        expression: "img.isPrimary"
      }
    }), _c('FormGroup', {
      staticClass: "mb-5",
      attrs: {
        "id": "img-isHidden-".concat(img._id),
        "description-position": "top",
        "description": "Some reports display all images associated with an asset. Select 'Yes' to hide this image from those reports.",
        "type": "radio",
        "label": "Hide on reports?",
        "options": [{
          label: 'No',
          value: false
        }, {
          label: 'Yes',
          value: true
        }]
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('input', _vm.form);
        }
      },
      model: {
        value: img.isHidden,
        callback: function callback($$v) {
          _vm.$set(img, "isHidden", $$v);
        },
        expression: "img.isHidden"
      }
    }), _c('div', {
      staticClass: "d-flex"
    }, [_c('button', {
      staticClass: "btn btn-sm btn-primary text-nowrap mr-2",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit('save', true);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-save mr-1"
    }), _vm._v(" Save ")]), img.type === 'upload' ? _c('button', {
      staticClass: "btn btn-danger text-nowrap",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickDeleteImage(img);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash mr-1"
    }), _vm._v(" Delete ")]) : _vm._e()])], 1)]);
  })], 2) : _vm.selectedCategoryId === 'portfolios' && _vm.id ? _c('div', {
    staticClass: "col-xl-10"
  }, [_c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("Manage the portfolios that this asset is a part of. Changes will be saved automatically.")]), _c('FormGroup', {
    attrs: {
      "id": "assetGroup",
      "label": "Selected Options",
      "type": "select-array-list",
      "options": _vm.assetGroupOptions,
      "search": true,
      "overflow": ""
    },
    on: {
      "itemAdded": _vm.onAddAssetGroup,
      "itemRemoved": _vm.onRemoveAssetGroup
    },
    model: {
      value: _vm.assetGroupForm,
      callback: function callback($$v) {
        _vm.assetGroupForm = $$v;
      },
      expression: "assetGroupForm"
    }
  })], 1) : _vm.selectedCategoryId === 'status' ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("The current status and history of this asset.")]), _c('div', {
    staticClass: "mb-4 font-w600 font-size-h5"
  }, [_vm._v(" Status: "), _c('span', {
    staticClass: "text-capitalize",
    class: {
      'text-success': _vm.value.status === 'active',
      'text-warning': _vm.value.status === 'inactive'
    }
  }, [_vm._v(" " + _vm._s(_vm.value.status))])]), _vm.sortedStatusHistory.length > 0 ? _c('table', {
    staticClass: "table"
  }, [_vm._m(2), _c('tbody', _vm._l(_vm.sortedStatusHistory, function (item) {
    return _c('tr', {
      key: item._id
    }, [_c('td', [_vm._v(_vm._s(_vm._f("date")(item.date)))]), _c('td', [_vm._v(_vm._s(_vm._f("capitalize")(item.value)))]), _c('td', [_vm._v(_vm._s(item.notes || '-'))]), _c('td', {
      staticClass: "text-right"
    }, [_c('button', {
      staticClass: "btn btn-link text-secondary mr-2",
      on: {
        "click": function click() {
          return _vm.onClickEditStatus(item);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pencil"
    })]), _c('button', {
      staticClass: "btn btn-link text-danger",
      on: {
        "click": function click() {
          return _vm.onClickDeleteStatus(item);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])])]);
  }), 0)]) : _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No status history.")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "add-status-btn"
    },
    on: {
      "click": _vm.onClickAddStatus
    }
  }, [_vm._v("Add Status")])]) : _vm.selectedCategoryId === 'address' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("Enter the address details of this site.")]), _vm.form.address ? _c('AddressForm', {
    attrs: {
      "error": _vm.error
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  }) : _vm._e()], 1) : _vm.selectedCategoryId === 'building' ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("More details about this building, such as tenancy, construction year etc.")]), _c('FormGroup', {
    attrs: {
      "id": "tenure",
      "error": _vm.error['tenure'],
      "options": [{
        value: 'freehold',
        label: 'Freehold'
      }, {
        value: 'leasehold',
        label: 'Leasehold'
      }],
      "type": "radio",
      "label": "Tenure"
    },
    model: {
      value: _vm.form.tenure,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tenure", $$v);
      },
      expression: "form.tenure"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "noOfFloors",
      "error": _vm.error['noOfFloors'],
      "type": "number",
      "label": "Number of floors",
      "placeholder": "Enter number of floors...",
      "min": 1,
      "max": 100
    },
    model: {
      value: _vm.form.noOfFloors,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "noOfFloors", $$v);
      },
      expression: "form.noOfFloors"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "floorArea"
    }
  }, [_vm._v("Floor Area")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    staticClass: "input-group",
    attrs: {
      "id": "floorArea",
      "label": "Floor Area",
      "error": _vm.error.floorArea,
      "type": "text"
    },
    model: {
      value: _vm.form.floorArea,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "floorArea", $$v);
      },
      expression: "form.floorArea"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    attrs: {
      "id": "floorAreaUnit",
      "label": "Floor Area Unit",
      "error": _vm.error.floorAreaUnit,
      "options": [{
        value: 'metric',
        label: 'm2'
      }, {
        value: 'imperial',
        label: 'sq. ft'
      }],
      "type": "select",
      "is-alt": true
    },
    model: {
      value: _vm.form.floorAreaUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "floorAreaUnit", $$v);
      },
      expression: "form.floorAreaUnit"
    }
  })], 1), _c('FormError', {
    attrs: {
      "id": "floorArea",
      "error": (_vm$error$floorArea = _vm.error.floorArea) === null || _vm$error$floorArea === void 0 ? void 0 : _vm$error$floorArea.message
    }
  })], 1)]), _c('FormGroup', {
    attrs: {
      "id": "occupationStatus",
      "error": _vm.error['occupationStatus'],
      "options": [{
        value: 'occupied',
        label: 'Occupied'
      }, {
        value: 'vacant',
        label: 'Vacant'
      }, {
        value: 'other',
        label: 'Other'
      }],
      "type": "select",
      "label": "Building Status"
    },
    model: {
      value: _vm.form.occupationStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "occupationStatus", $$v);
      },
      expression: "form.occupationStatus"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "error": _vm.error['type'],
      "options": [{
        value: 'standing investment',
        label: 'Standing Investment'
      }, {
        value: 'new construction',
        label: 'New Construction'
      }, {
        value: 'major renovation',
        label: 'Major Renovation'
      }],
      "type": "select",
      "label": "Building Type"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "vacancyRate",
      "error": _vm.error['vacancyRate'],
      "label": "Vacancy Rate",
      "type": "text"
    },
    model: {
      value: _vm.form.vacancyRate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vacancyRate", $$v);
      },
      expression: "form.vacancyRate"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "constructionYear",
      "error": _vm.error['constructionYear'],
      "label": "Construction Year",
      "type": "datePicker",
      "date-format": "yyyy",
      "date-min-view-mode": "years",
      "placeholder": "Select a date..."
    },
    model: {
      value: _vm.form.constructionYear,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "constructionYear", $$v);
      },
      expression: "form.constructionYear"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "ownedSince",
      "error": _vm.error['ownedSince'],
      "label": "Owned Since",
      "type": "datePicker",
      "placeholder": "Select a date..."
    },
    model: {
      value: _vm.form.ownedSince,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ownedSince", $$v);
      },
      expression: "form.ownedSince"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "disposalDate",
      "error": _vm.error['disposalDate'],
      "label": "Disposal Date",
      "type": "datePicker",
      "placeholder": "Select a date..."
    },
    model: {
      value: _vm.form.disposalDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "disposalDate", $$v);
      },
      expression: "form.disposalDate"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "tenantControlledPercent"
    }
  }, [_vm._v("Tenant Controlled")]), _c('RangeSlider', {
    attrs: {
      "postfix": "%"
    },
    on: {
      "onSlide": _vm.onTenantSlide
    },
    model: {
      value: _vm.form.tenantControlledPercent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tenantControlledPercent", $$v);
      },
      expression: "form.tenantControlledPercent"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "landlordControlledPercent"
    }
  }, [_vm._v("Landlord Controlled")]), _c('RangeSlider', {
    attrs: {
      "postfix": "%"
    },
    on: {
      "onSlide": _vm.onLandlordSlide
    },
    model: {
      value: _vm.form.landlordControlledPercent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "landlordControlledPercent", $$v);
      },
      expression: "form.landlordControlledPercent"
    }
  })], 1), _c('FormGroup', {
    attrs: {
      "id": "occupancyLevel",
      "error": _vm.error['occupancyLevel'],
      "label": "Occupancy Level",
      "type": "text"
    },
    model: {
      value: _vm.form.occupancyLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "occupancyLevel", $$v);
      },
      expression: "form.occupancyLevel"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "dm-profile-edit-name"
    }
  }, [_vm._v("Gross Asset Value")]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('CurrencySelect', {
    staticClass: "form-control form-control-alt",
    attrs: {
      "id": "gavCurrency",
      "type": "text",
      "name": "gavCurrency",
      "placeholder": "Enter a Gross Asset Value Currency..."
    },
    model: {
      value: _vm.form.gavCurrency,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gavCurrency", $$v);
      },
      expression: "form.gavCurrency"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.gav,
      expression: "form.gav"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "gav",
      "type": "text",
      "name": "gav",
      "placeholder": "Enter a Gross Asset Value..."
    },
    domProps: {
      "value": _vm.form.gav
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "gav", $event.target.value);
      }
    }
  })])]), _c('FormGroup', {
    attrs: {
      "id": "gresbId",
      "error": _vm.error['gresbId'],
      "type": "text",
      "label": "GRESB ID"
    },
    model: {
      value: _vm.form.gresbId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gresbId", $$v);
      },
      expression: "form.gresbId"
    }
  })], 1) : _vm.selectedCategoryId === 'benchmarks' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("All settings related to benchmarks such as REEB, LETI and CRREM.")]), _vm._l(_vm.benchmarks, function (benchmark) {
    return _c('div', {
      key: benchmark._id,
      staticClass: "mb-5"
    }, [_c('h4', {
      staticClass: "mb-2"
    }, [_vm._v(_vm._s(benchmark.name))]), _c('p', {
      staticClass: "mb-3"
    }, [_vm._v(_vm._s(benchmark.description))]), _vm._l(benchmark.fields, function (field) {
      var _vm$form$benchmarks, _vm$form$benchmarks$f;

      return _c('FormGroup', {
        key: field.code,
        attrs: {
          "id": "".concat(benchmark._id, "-").concat(field.code),
          "label": field.name,
          "type": field.formType,
          "options": field.data.map(function (c) {
            return {
              value: c,
              label: c
            };
          }),
          "value": (_vm$form$benchmarks = _vm.form.benchmarks) === null || _vm$form$benchmarks === void 0 ? void 0 : (_vm$form$benchmarks$f = _vm$form$benchmarks.find(function (b) {
            return b.benchmarkId === benchmark._id && field.code === b.fieldCode;
          })) === null || _vm$form$benchmarks$f === void 0 ? void 0 : _vm$form$benchmarks$f.fieldValue
        },
        on: {
          "input": function input(val) {
            return _vm.onSelectBenchmark(benchmark._id, field, val);
          }
        }
      });
    })], 2);
  })], 2) : _vm.selectedCategoryId === 'integrations' ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("Third-party integration settings for this asset.")]), _c('FormGroup', {
    attrs: {
      "id": "gresbId",
      "error": _vm.error['gresbId'],
      "type": "text",
      "label": "GRESB ID"
    },
    model: {
      value: _vm.form.gresbId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gresbId", $$v);
      },
      expression: "form.gresbId"
    }
  })], 1) : _c('div', {
    staticClass: "col-xl-5"
  }, [_c('p', {
    staticClass: "font-w600"
  }, [_vm._v("Select a category from the left.")])])]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.status,
      "title": "Change status"
    },
    on: {
      "close": function close($event) {
        _vm.modals.status = false;
      },
      "submit": _vm.onConfirmStatusModal
    }
  }, [_vm.modals.status ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "date",
      "label": "Date",
      "type": "datePicker"
    },
    model: {
      value: _vm.modals.status.date,
      callback: function callback($$v) {
        _vm.$set(_vm.modals.status, "date", $$v);
      },
      expression: "modals.status.date"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "value",
      "label": "Status",
      "type": "select",
      "options": _vm.modals.status.options
    },
    model: {
      value: _vm.modals.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.modals.status, "value", $$v);
      },
      expression: "modals.status.value"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "note",
      "label": "Notes",
      "type": "textarea"
    },
    model: {
      value: _vm.modals.status.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.modals.status, "notes", $$v);
      },
      expression: "modals.status.notes"
    }
  })], 1) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.deleteStatus,
      "title": "Delete status"
    },
    on: {
      "close": function close($event) {
        _vm.modals.deleteStatus = false;
      },
      "submit": _vm.onConfirmDeleteStatusModal
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.deleteImage,
      "title": "Delete image"
    },
    on: {
      "close": function close($event) {
        _vm.modals.deleteImage = false;
      },
      "submit": _vm.onDeleteImage
    }
  }, [_vm.modals.deleteImage ? _c('div', [_c('p', [_vm._v(" Are you sure you want to delete this image from "), _c('strong', [_vm._v(_vm._s(_vm.form.siteName))]), _vm._v("? ")]), _c('a', {
    attrs: {
      "href": _vm.modals.deleteImage.url,
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid AssetForm-image mb-4",
    attrs: {
      "src": _vm.modals.deleteImage.url
    }
  })])]) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.uploadImage,
      "title": "Upload image"
    },
    on: {
      "close": _vm.onCloseUploadImageModal,
      "submit": _vm.onSubmitImage
    }
  }, [_vm.modals.uploadImage ? _c('div', [_c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v(" To add a new image, click the upload box below or simply drag an image onto it. Once added, you'll see it under the "), _c('span', {
    staticClass: "text-secondary"
  }, [_c('i', {
    staticClass: "fa fa-user text-primary mr-1"
  }), _vm._v(" Uploaded Images")]), _vm._v(" section. ")]), _c('FileUpload', {
    staticClass: "bg-light mb-4",
    attrs: {
      "progress": _vm.newImageFile.progress,
      "error": _vm.newImageFile.error,
      "loading": _vm.newImageFile.uploading,
      "file-types": ['png', 'jpg', 'jpeg', 'gif', 'svg'],
      "button-only": ""
    },
    on: {
      "input": _vm.onSelectFile,
      "reset": function reset($event) {
        _vm.error = null;
      }
    },
    model: {
      value: _vm.newImageFile.file,
      callback: function callback($$v) {
        _vm.$set(_vm.newImageFile, "file", $$v);
      },
      expression: "newImageFile.file"
    }
  }), _vm.imagePreview ? _c('div', {
    staticClass: "mb-4"
  }, [_c('p', {
    staticClass: "mb-2 font-w600"
  }, [_vm._v("Preview")]), _c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.imagePreview
    }
  })]) : _vm._e()], 1) : _vm._e()])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticClass: "mb-1"
  }, [_c('i', {
    staticClass: "fa fa-user text-primary mr-1"
  }), _vm._v(" Uploaded Images")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xl-8 col-lg-12 mb-lg-0 mb-4"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_c('i', {
    staticClass: "fa fa-street-view text-primary mr-1"
  }), _vm._v(" Automatically Fetched Images")]), _c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v(" Below are images of this asset we have found via Google and Bing Maps. These can't be deleted, but you can exclude them from being used in reports. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Date")]), _c('th', [_vm._v("Value")]), _c('th', [_vm._v("Notes")]), _c('th', {
    staticClass: "text-right"
  })])]);
}]

export { render, staticRenderFns }