import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'import',
    plural: 'imports'
  }),

  GET_UPLOAD_URL_REQUEST: state => {
    state.loadingAction.getUploadUrl = true;
    state.errorAction.getUploadUrl = false;
  },
  GET_UPLOAD_URL_SUCCESS: (state, body) => {
    state.loadingAction.getUploadUrl = false;
    state.errorAction.getUploadUrl = false;

    state.setup.url = body;
  },
  GET_UPLOAD_URL_ERROR: (state, error) => {
    state.loadingAction.getUploadUrl = false;
    state.errorAction.getUploadUrl = error;

    state.setup.url = false;
  },

  GET_IMPORTED_ITEMS_REQUEST: state => {
    state.loadingAction.getImportedItems = true;
    state.errorAction.getImportedItems = false;
  },
  GET_IMPORTED_ITEMS_SUCCESS: (state, body) => {
    state.loadingAction.getImportedItems = false;
    state.errorAction.getImportedItems = false;

    state.importedItems = body;
  },
  GET_IMPORTED_ITEMS_ERROR: (state, error) => {
    state.loadingAction.getImportedItems = false;
    state.errorAction.getImportedItems = error;

    state.importedItems = [];
  },

  GET_IMPORT_TEMPLATE_REQUEST: state => {
    state.loadingAction.getImportTemplate = true;
    state.errorAction.getImportTemplate = false;
  },
  GET_IMPORT_TEMPLATE_SUCCESS: (state, body) => {
    state.loadingAction.getImportTemplate = false;
    state.errorAction.getImportTemplate = false;

    state.importTemplate = body;
  },
  GET_IMPORT_TEMPLATE_ERROR: (state, error) => {
    state.loadingAction.getImportTemplate = false;
    state.errorAction.getImportTemplate = error;

    state.importTemplates = {};
  },

  GET_IMPORT_TEMPLATES_REQUEST: state => {
    state.loadingAction.getImportTemplates = true;
    state.errorAction.getImportTemplates = false;
  },
  GET_IMPORT_TEMPLATES_SUCCESS: (state, body) => {
    state.loadingAction.getImportTemplates = false;
    state.errorAction.getImportTemplates = false;

    state.importTemplates = body.data;
  },
  GET_IMPORT_TEMPLATES_ERROR: (state, error) => {
    state.loadingAction.getImportTemplates = false;
    state.errorAction.getImportTemplates = error;

    state.importTemplates = [];
  },

  POST_ROLLBACK_REQUEST: state => {
    state.loadingAction.rollback = true;
    state.errorAction.rollback = false;
  },
  POST_ROLLBACK_SUCCESS: (state, body) => {
    state.loadingAction.rollback = false;
    state.errorAction.rollback = false;

    state.import = body.import;
  },
  POST_ROLLBACK_ERROR: (state, error) => {
    state.loadingAction.rollback = false;
    state.errorAction.rollback = error;
  },

  GET_INFO_REQUEST: state => {
    state.loadingAction.getInfo = true;
    state.errorAction.getInfo = false;
  },
  GET_INFO_SUCCESS: (state, body) => {
    state.loadingAction.getInfo = false;
    state.errorAction.getInfo = false;

    state.setup.info = body;
  },
  GET_INFO_ERROR: (state, error) => {
    state.loadingAction.getInfo = false;
    state.errorAction.getInfo = error;

    state.setup.info = false;
  },

  POST_MAPPING_REQUEST: state => {
    state.loadingAction.postMapping = true;
    state.errorAction.postMapping = false;
  },
  POST_MAPPING_SUCCESS: (state, body) => {
    state.loadingAction.postMapping = false;
    state.errorAction.postMapping = false;

    state.mapping.suggested = body;
  },
  POST_MAPPING_ERROR: (state, error) => {
    state.loadingAction.postMapping = false;
    state.errorAction.postMapping = error;
  },

  SET_USER_MAPPING: (state, mapping) => {
    state.mapping.user = mapping;
    localStorage.setItem(`ETN-IMPORT-${state.id}`, JSON.stringify(mapping));
  },

  POST_PREVIEW_REQUEST: state => {
    state.loadingAction.postPreview = true;
    state.errorAction.postPreview = false;
  },
  POST_PREVIEW_SUCCESS: (state, body) => {
    state.loadingAction.postPreview = false;
    state.errorAction.postPreview = false;

    state.preview = body;
  },
  POST_PREVIEW_ERROR: (state, error) => {
    state.loadingAction.postPreview = false;
    state.errorAction.postPreview = error;

    state.preview = {};
  },

  POST_IMPORT_REQUEST: state => {
    state.loadingAction.postImport = true;
    state.errorAction.postImport = false;
  },
  POST_IMPORT_SUCCESS: (state, body) => {
    state.loadingAction.postImport = false;
    state.errorAction.postImport = false;

    state.results = body;
  },
  POST_IMPORT_ERROR: (state, error) => {
    state.loadingAction.postImport = false;
    state.errorAction.postImport = error;

    state.results = { status: 'error' };
  }
};
