import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'import',
    plural: 'imports'
  }),
  getUploadUrl: async ({ commit }, { type, importTemplateId }) => {
    try {
      commit('GET_UPLOAD_URL_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/imports/upload-url`, {
        params: {
          type,
          importTemplateId
        }
      });
      commit('GET_UPLOAD_URL_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_UPLOAD_URL_ERROR', {
        message: 'There was a problem getting an upload URL.',
        data: e.body
      });
      return e;
    }
  },
  getImportedItems: async ({ commit }, { id }) => {
    try {
      commit('GET_IMPORTED_ITEMS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/imports/${id}/items`);
      commit('GET_IMPORTED_ITEMS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_IMPORTED_ITEMS_ERROR', {
        message: 'There was a problem.',
        data: e.body
      });
      return e;
    }
  },
  rollback: async ({ commit }, { id }) => {
    try {
      commit('POST_ROLLBACK_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/imports/${id}/rollback`);
      commit('POST_ROLLBACK_SUCCESS', body);
      return body;
    } catch (e) {
      commit('POST_ROLLBACK_ERROR', {
        message: 'There was a problem.',
        data: e.body
      });
      return e;
    }
  },
  getImportTemplate: async ({ commit }, { id, params }) => {
    try {
      commit('GET_IMPORT_TEMPLATE_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/import-templates/${id}`, { params });
      commit('GET_IMPORT_TEMPLATE_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_IMPORT_TEMPLATE_ERROR', {
        message: 'There was a problem getting an import template.',
        data: e.body
      });
      return e;
    }
  },
  getImportTemplates: async ({ commit }) => {
    try {
      commit('GET_IMPORT_TEMPLATES_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/import-templates`);
      commit('GET_IMPORT_TEMPLATES_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_IMPORT_TEMPLATES_ERROR', {
        message: 'There was a problem getting an import templates.',
        data: e.body
      });
      return e;
    }
  },
  getInfo: async ({ commit }, { type, importTemplateId }) => {
    try {
      commit('GET_INFO_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_IMPORT_URL}/info`, {
        params: {
          type,
          importTemplateId
        }
      });
      commit('GET_INFO_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_INFO_ERROR', {
        message: 'There was a problem getting an upload URL.',
        data: e.body
      });
      return e;
    }
  },
  postMapping: async ({ commit }, { id, type }) => {
    try {
      commit('POST_MAPPING_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_IMPORT_URL}/mapping`, { id, type });
      commit('POST_MAPPING_SUCCESS', body);
      return body;
    } catch (e) {
      commit('POST_MAPPING_ERROR', {
        message: 'There was a problem getting mapping data.',
        data: e.body
      });
      return e;
    }
  },
  postPreview: async ({ commit }, { id, type, mapping }) => {
    try {
      commit('POST_PREVIEW_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_IMPORT_URL}/preview`, { id, type, mapping });
      commit('POST_PREVIEW_SUCCESS', body);
      return body;
    } catch (e) {
      commit('POST_PREVIEW_ERROR', {
        message: 'There was a problem getting preview data.',
        data: e.body
      });
      return e;
    }
  },
  postImport: async ({ commit }, { id, type, mapping }) => {
    try {
      commit('POST_IMPORT_REQUEST');
      const { body } = await Vue.http.post(`${process.env.VUE_APP_IMPORT_URL}/import`, { id, type, mapping });
      commit('POST_IMPORT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('POST_IMPORT_ERROR', {
        message: 'There was a problem getting import data.',
        data: e.body
      });
      return e;
    }
  }
};
