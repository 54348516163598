<template>
  <div>
    <PageHeaderDisplay
      title="Dashboard"
      sub-title="Detailed graphs and tables for your portfolios and assets"
      category="Analytics"
      category-logo="fa-file-chart-column"
      :category-route="{ name: $route.name }"
    />
    <div class="content">
      <div class="bg-lighter rounded-md px-4 py-3 mb-4">
        <div class="d-flex align-items-center">
          <div class="input-group">
            <FormItem
              id="filterType"
              v-model="options.type"
              :options="[
                { value: 'asset', label: 'Asset' },
                { value: 'portfolio', label: 'Portfolio' }
              ]"
              type="select"
              alt
              style="max-width: 40px"
              @input="onChangeType"
            />
            <FormItem
              id="selectedId"
              v-model="options.selectedId"
              :options="sortedOptions"
              type="select2"
              placeholder="Select Item"
              style="max-width: 20rem"
              :config="{ allowClear: true, allowSearch: true }"
              @input="onSelectItem"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Date Range</div>
            <FormItem
              id="dateRange"
              v-model="options.dateRange"
              type="dateRangePicker"
              :disabled="loadingAction.getConsumptions"
              class="mb-0"
              @input="onDateRangeUpdate"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Compared To</div>
            <FormItem
              id="graph-compare-period"
              v-model="options.comparePeriod"
              class="mb-0"
              type="select"
              style="width: 300px"
              :options="comparePeriods"
              @input="onComparePeriodUpdate"
            />
          </div>
          <div v-if="$route.path !== '/analytics/costs'" class="d-flex align-items-center">
            <div class="form-static-text pr-3">Source</div>
            <FormItem
              id="source"
              v-model="options.source"
              class="mb-0"
              type="select"
              :options="[
                { label: 'Combined', value: 'combined' },
                { label: 'Invoice', value: 'invoice' },
                { label: 'Readings', value: 'reading' },
                { label: 'Custom Consumption', value: 'custom' },
                { label: 'HH Data', value: 'hh' }
              ]"
              @input="onSourceUpdate"
            />
          </div>
        </div>

        <!-- <div v-else-if="options.type === 'portfolio'" class="col-md-3 mb-3 mb-md-0 d-flex align-items-center border-left">
          <div class="font-w600 mr-3 text-capitalize" style="white-space: pre">{{ options.type }}</div>
          <FormItem
            id="asset-group"
            :value="options.selectedAssetGroupId"
            class="input-fx-pop"
            type="select2"
            :options="sortedAssetGroups.map(ag => ({ label: ag.name, value: ag._id }))"
            @input="onSelectAssetGroup"
          />
        </div> -->
      </div>
      <router-view v-if="options.selectedId" />
      <div v-else>
        <div class="mt-5 text-center alert alert-warning">Select a site above to view analytics.</div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import FormItem from '@/components/FormItem';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';

export default {
  name: 'AnalyticsBase',
  components: {
    FormItem,
    PageHeaderDisplay
  },
  computed: {
    ...mapGetters({
      analytics: 'analytics/analytics',
      options: 'analytics/options',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      allAssets: 'analytics/allAssets',
      allAssetGroups: 'analytics/allAssetGroups',
      comparePeriods: 'analytics/comparePeriods',
      utilityTypes: 'util/utilityTypes'
    }),
    sortedOptions() {
      return [{ label: '', value: null }, ...(this.options.type === 'asset' ? this.sortedAssets() : this.sortedAssetGroups())];
    }
  },
  beforeMount() {
    this.setRouteOptions();
  },
  async mounted() {
    await Promise.all([this.getAssets({ data: { params: { $limit: 99999, $select: '_id,floorArea,siteName' } } }), this.getAssetGroups()]);

    const { id: routeId } = this.$route.query;
    if (routeId) {
      this.setOption({ key: 'selectedId', option: routeId });
      await this.getAccountTypes({ data: this.options });
      this.refreshGraphs();
    }
  },
  beforeDestroy() {
    this.clearAnalyticsStore();
  },
  methods: {
    ...mapActions({
      getAssetGroups: 'analytics/getAssetGroups',
      getAssets: 'analytics/getAssets',
      refreshGraphs: 'analytics/refreshGraphs',
      getAccounts: 'asset/accounts',
      getAccountTypes: 'analytics/getAccountTypes'
    }),
    ...mapMutations({
      setOption: 'analytics/SET_OPTION',
      updateComparisonPeriods: 'analytics/UPDATE_COMPARISON_PERIODS',
      clearAnalyticsStore: 'analytics/CLEAR_STORE',
      setLoading: 'analytics/SET_LOADING'
    }),
    onChangeType() {
      this.setOption({ key: 'selectedId', option: null });

      const currentPath = this.$route.path;
      if (this.$route.query.id) this.$router.replace(currentPath);
    },
    sortedAssets() {
      const sortedAssets = [...this.allAssets];

      sortedAssets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return sortedAssets.map(a => ({ label: a.siteName, value: a._id }));
    },
    sortedAssetGroups() {
      const sortedAssetGroups = [...this.allAssetGroups];

      sortedAssetGroups.sort((a, b) => a.name.localeCompare(b.name));

      return sortedAssetGroups.map(a => ({ label: a.name, value: a._id }));
    },
    setRouteOptions() {
      this.setOption({ key: 'page', option: this.$route.path.split('/').pop() });
      const { type: routeType, dateRange, comparePeriod, source } = this.$route.query;

      if (routeType) {
        this.setOption({ key: 'type', option: routeType });
      }

      if (source) this.setOption({ key: 'source', option: source });

      if (dateRange) {
        const [startDate, endDate] = dateRange.split('|');
        this.options.dateRange = [moment(startDate).toDate(), moment(endDate).toDate()];
        if (comparePeriod) this.options.comparePeriod = comparePeriod;
        this.updateComparisonPeriods();
      }
    },
    onComparePeriodUpdate(period) {
      this.setOption({ key: 'comparePeriod', option: period });
      this.refreshGraphs();
      this.updateRoute();
    },
    onDateRangeUpdate() {
      this.updateComparisonPeriods();
      this.refreshGraphs();
      this.updateRoute();
    },
    async onSelectItem() {
      this.setLoading();
      await this.getAccountTypes({ data: this.options });
      this.refreshGraphs();
      this.updateRoute();
      this.setLoading();
    },
    onSourceUpdate(source) {
      this.setOption({ key: 'source', option: source });
      this.refreshGraphs();
      this.updateRoute();
    },
    updateRoute() {
      const { type, dateRange, selectedId, comparePeriod, source } = this.options;

      const updatedQuery = selectedId
        ? {
            type,
            id: selectedId,
            dateRange: moment(dateRange[0]).format('YYYY-MM-DD') + '|' + moment(dateRange[1]).format('YYYY-MM-DD'),
            comparePeriod,
            source
          }
        : {};

      if (Object.keys(updatedQuery).every(key => this.$route.query[key] === updatedQuery[key])) return false;

      this.$router.push({
        name: this.$route.name,
        query: updatedQuery
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path !== from.path) {
      this.setOption({ key: 'page', option: to.path.split('/').pop() });
      if (this.options.selectedId) {
        this.refreshGraphs();
      }
    }
    next();
  }
};
</script>
