var render = function () {
  var _vm$error$floorArea, _vm$error$thirdPartie;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-3 col-lg-4 col-md-5 col-12"
  }, [_c('h4', [_vm._v("Manage")]), _c('VerticalPills', {
    attrs: {
      "items": _vm.categories,
      "item-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "nav-link",
          class: {
            active: _vm.selectedCategoryId === item.id
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSelectCategory(item);
            }
          }
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_c('i', {
          staticClass: "fa fa-fw mr-1 text-primary",
          class: "".concat(item.icon)
        }), _vm._v(" " + _vm._s(item.title))])])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": _vm.selectedCategory.icon
    }
  }, [_vm._v(_vm._s(_vm.selectedCategory.title))]), _vm.selectedCategoryId === 'general' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "disabled": _vm.disabled,
      "error": _vm.error['name'],
      "label": "Name",
      "type": "text",
      "description": "Your own reference for this account/meter."
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "disabled": _vm.disabled,
      "error": _vm.error['type'],
      "label": "Utility Type",
      "description": "The type of utility this account/meter is for.",
      "type": "select",
      "options": _vm.utilityTypeOptions
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "tags",
      "placeholder": "Add a tag...",
      "disabled": _vm.disabled,
      "error": _vm.error['tags'],
      "label": "Tags",
      "type": "tag-manager",
      "description": "Categorise accounts and enable filtering in dynamic reports."
    },
    model: {
      value: _vm.form.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tags", $$v);
      },
      expression: "form.tags"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "parentAccountId",
      "disabled": _vm.disabled || _vm.accountOptions.length === 0,
      "error": _vm.error['parentAccountId'],
      "label": "Parent Account (For sub-meters)",
      "config": {
        allowClear: true
      },
      "description": "The parent account this sub-meter belongs to. Leave blank if this is an primary account/meter",
      "type": "select2",
      "options": _vm.accountOptions
    },
    model: {
      value: _vm.form.parentAccountId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parentAccountId", $$v);
      },
      expression: "form.parentAccountId"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "group",
      "disabled": _vm.disabled,
      "error": _vm.error['group'],
      "description": "An optional group name to group multiple sub-meters (e.g. Floor 1)",
      "label": "Group",
      "type": "text"
    },
    model: {
      value: _vm.form.group,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "group", $$v);
      },
      expression: "form.group"
    }
  })], 1) : _vm.selectedCategoryId === 'identification' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_vm.form.type === 'electricity' ? _c('FormGroup', {
    attrs: {
      "id": "meterPointNumber",
      "disabled": _vm.disabled,
      "error": _vm.error['meterPointNumber'],
      "label": "MPAN",
      "type": "text"
    },
    model: {
      value: _vm.form.meterPointNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterPointNumber", $$v);
      },
      expression: "form.meterPointNumber"
    }
  }) : _vm._e(), _vm.form.type === 'gas' ? _c('FormGroup', {
    attrs: {
      "id": "meterPointNumber",
      "disabled": _vm.disabled,
      "error": _vm.error['meterPointNumber'],
      "label": "MPR",
      "type": "text"
    },
    model: {
      value: _vm.form.meterPointNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterPointNumber", $$v);
      },
      expression: "form.meterPointNumber"
    }
  }) : _vm._e(), _vm.form.type === 'water' ? _c('FormGroup', {
    attrs: {
      "id": "meterPointNumber",
      "disabled": _vm.disabled,
      "error": _vm.error['meterPointNumber'],
      "label": "Water SPID",
      "type": "text"
    },
    model: {
      value: _vm.form.meterPointNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterPointNumber", $$v);
      },
      expression: "form.meterPointNumber"
    }
  }) : _vm._e(), _vm.form.type === 'water' ? _c('FormGroup', {
    attrs: {
      "id": "meterPointNumber2",
      "disabled": _vm.disabled,
      "error": _vm.error['meterPointNumber2'],
      "label": "Waste SPID",
      "type": "text"
    },
    model: {
      value: _vm.form.meterPointNumber2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterPointNumber2", $$v);
      },
      expression: "form.meterPointNumber2"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "meterSerialNumber",
      "disabled": _vm.disabled,
      "error": _vm.error['meterSerialNumber'],
      "label": "Meter Serial Number",
      "type": "text"
    },
    model: {
      value: _vm.form.meterSerialNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterSerialNumber", $$v);
      },
      expression: "form.meterSerialNumber"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "siteCode",
      "disabled": _vm.disabled,
      "error": _vm.error['siteCode'],
      "description": "The site code for this account",
      "label": "Site Code",
      "type": "text"
    },
    model: {
      value: _vm.form.siteCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "siteCode", $$v);
      },
      expression: "form.siteCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "financialCode",
      "disabled": _vm.disabled,
      "error": _vm.error['financialCode'],
      "label": "Financial Code",
      "description": "The financial code for this account",
      "type": "text"
    },
    model: {
      value: _vm.form.financialCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "financialCode", $$v);
      },
      expression: "form.financialCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "propertyCode",
      "disabled": _vm.disabled,
      "error": _vm.error['propertyCode'],
      "label": "Property Code",
      "description": "Property code",
      "type": "text"
    },
    model: {
      value: _vm.form.propertyCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "propertyCode", $$v);
      },
      expression: "form.propertyCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "propertyScheduleCode",
      "disabled": _vm.disabled,
      "error": _vm.error['propertyScheduleCode'],
      "label": "Property Schedule Code",
      "description": "Property Schedule code",
      "type": "text"
    },
    model: {
      value: _vm.form.propertyScheduleCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "propertyScheduleCode", $$v);
      },
      expression: "form.propertyScheduleCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "tags",
      "placeholder": "Add a tag...",
      "disabled": _vm.disabled,
      "description-position": "top",
      "error": _vm.error['tags'],
      "label": "Tags",
      "type": "tag-manager",
      "description": "Categorise accounts and enable filtering in dynamic reports."
    },
    model: {
      value: _vm.form.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tags", $$v);
      },
      expression: "form.tags"
    }
  })], 1) : _vm._e(), _vm.selectedCategoryId === 'status' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_vm.isCreate ? _c('FormGroup', {
    attrs: {
      "id": "status",
      "disabled": _vm.disabled,
      "error": _vm.error['status'],
      "label": "Status",
      "type": "select",
      "options": [{
        label: 'Active',
        value: 'active'
      }, {
        label: 'Inactive',
        value: 'inactive'
      }]
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }) : _c('div', {
    staticClass: "mb-4 font-w600 font-size-h5"
  }, [_vm._v(" Status: "), _c('span', {
    staticClass: "text-capitalize",
    class: {
      'text-success': _vm.value.status === 'active',
      'text-warning': _vm.value.status === 'inactive'
    }
  }, [_vm._v(" " + _vm._s(_vm.value.status))])]), _vm.sortedStatusHistory.length > 0 ? _c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.sortedStatusHistory, function (item) {
    return _c('tr', {
      key: item._id
    }, [_c('td', [_vm._v(_vm._s(_vm._f("date")(item.date)))]), _c('td', [_vm._v(_vm._s(_vm._f("capitalize")(item.value)))]), _c('td', [_vm._v(_vm._s(item.notes || '-'))]), _c('td', {
      staticClass: "text-right"
    }, [_c('button', {
      staticClass: "btn btn-link text-secondary mr-2",
      on: {
        "click": function click() {
          return _vm.onClickEditStatus(item);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pencil"
    })]), _c('button', {
      staticClass: "btn btn-link text-danger",
      on: {
        "click": function click() {
          return _vm.onClickDeleteStatus(item);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])])]);
  }), 0)]) : _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No status history.")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "add-status-btn"
    },
    on: {
      "click": _vm.onClickAddStatus
    }
  }, [_vm._v("Add Status")])], 1) : _vm._e(), _vm.selectedCategoryId === 'supplier' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "supplierId",
      "disabled": _vm.disabled,
      "error": _vm.error['supplierId'],
      "options": _vm.sortedSuppliers.map(function (s) {
        return {
          label: s.name,
          value: s._id
        };
      }),
      "label": "Supplier Name",
      "type": "select2"
    },
    model: {
      value: _vm.form.supplierId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "supplierId", $$v);
      },
      expression: "form.supplierId"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "supplierRef",
      "disabled": _vm.disabled,
      "error": _vm.error['supplierRef'],
      "label": "Supplier/Account Ref",
      "type": "text"
    },
    model: {
      value: _vm.form.supplierRef,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "supplierRef", $$v);
      },
      expression: "form.supplierRef"
    }
  }), _vm.form.type === 'electricity' ? _c('FormGroup', {
    attrs: {
      "id": "esec",
      "disabled": _vm.disabled,
      "error": _vm.error['esec'],
      "label": "Electricity Supply Emergency Code",
      "options": _vm.esecOptions,
      "type": "select"
    },
    model: {
      value: _vm.form.esec,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "esec", $$v);
      },
      expression: "form.esec"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.selectedCategoryId === 'solar' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "solarType",
      "disabled": _vm.disabled,
      "error": _vm.error['solarType'],
      "label": "Solar Type",
      "type": "select",
      "description": "The primary purpose of the solar PV system",
      "options": [{
        label: 'Generation & Export',
        value: 'both'
      }, {
        label: 'Generation',
        value: 'generation'
      }, {
        label: 'Export',
        value: 'export'
      }]
    },
    model: {
      value: _vm.form.solarType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "solarType", $$v);
      },
      expression: "form.solarType"
    }
  })], 1) : _vm._e(), _vm.selectedCategoryId === 'reporting' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "reportingType",
      "disabled": _vm.disabled,
      "error": _vm.error['reportingType'],
      "label": "Reporting Type",
      "type": "select",
      "options": [{
        label: 'Default',
        value: null
      }, {
        label: 'Like for like',
        value: 'likeForLike'
      }, {
        label: 'Absolute',
        value: 'absolute'
      }]
    },
    model: {
      value: _vm.form.reportingType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reportingType", $$v);
      },
      expression: "form.reportingType"
    }
  }), _c('label', [_vm._v("LOA Uploaded ")]), _c('div', {
    staticClass: "text-gray-dark font-w600 mt-1 form-group"
  }, [_c('span', [_vm.form.gridfetchLoa ? _c('i', {
    staticClass: "fa fa-lg fa-check-circle text-success"
  }) : _c('i', {
    staticClass: "fa fa-lg fa-times-circle text-danger"
  })]), _vm.form.gridfetchLoa ? _c('span', [_vm._v(" Expiry Date: " + _vm._s(_vm._f("date")(_vm.form.gridfetchLoa.expiryDate)) + " ")]) : _vm._e()]), _c('FormGroup', {
    attrs: {
      "id": "isTrc",
      "disabled": _vm.disabled,
      "error": _vm.error['isTrc'],
      "label": "Tenant Recharge Account",
      "type": "radio",
      "options": [{
        value: true,
        label: 'Yes'
      }, {
        value: false,
        label: 'No'
      }],
      "description": "Displays a warning within the account validation area for invoices linked to this account"
    },
    model: {
      value: _vm.form.isTrc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isTrc", $$v);
      },
      expression: "form.isTrc"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "financialCode",
      "disabled": _vm.disabled,
      "error": _vm.error['financialCode'],
      "label": "Financial Code",
      "description": "The financial code for this account",
      "type": "text"
    },
    model: {
      value: _vm.form.financialCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "financialCode", $$v);
      },
      expression: "form.financialCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "financialTransactionType",
      "disabled": _vm.disabled,
      "error": _vm.error['financialTransactionType'],
      "label": "Financial Transaction Type",
      "description": "The financial transaction type",
      "type": "text"
    },
    model: {
      value: _vm.form.financialTransactionType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "financialTransactionType", $$v);
      },
      expression: "form.financialTransactionType"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "paymentSystem",
      "disabled": _vm.disabled,
      "error": _vm.error['paymentSystem'],
      "label": "Payment System",
      "description": "Payment system for this account",
      "type": "text"
    },
    model: {
      value: _vm.form.paymentSystem,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paymentSystem", $$v);
      },
      expression: "form.paymentSystem"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "paymentTo",
      "disabled": _vm.disabled,
      "error": _vm.error['paymentTo'],
      "label": "Payment To",
      "description": "Where does payment go to?",
      "type": "text"
    },
    model: {
      value: _vm.form.paymentTo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paymentTo", $$v);
      },
      expression: "form.paymentTo"
    }
  })], 1) : _vm._e(), _vm.selectedCategoryId === 'meter' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "meterUnits",
      "disabled": _vm.disabled,
      "error": _vm.error['meterUnits'],
      "label": "Meter Units",
      "type": "select",
      "options": _vm.unitOptions
    },
    model: {
      value: _vm.form.meterUnits,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterUnits", $$v);
      },
      expression: "form.meterUnits"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "automaticMeterRead",
      "disabled": _vm.disabled,
      "error": _vm.error['automaticMeterRead'],
      "label": "Automatic Meter Read",
      "type": "radio",
      "options": [{
        value: true,
        label: 'Yes'
      }, {
        value: false,
        label: 'No'
      }]
    },
    model: {
      value: _vm.form.automaticMeterRead,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "automaticMeterRead", $$v);
      },
      expression: "form.automaticMeterRead"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "tenant",
      "disabled": _vm.disabled,
      "error": _vm.error['tenantLandlord'],
      "label": "Tenant/Landlord",
      "type": "select",
      "options": [{
        label: 'Tenant',
        value: 'tenant'
      }, {
        label: 'Landlord',
        value: 'landlord'
      }, {
        label: 'Void',
        value: 'void'
      }]
    },
    model: {
      value: _vm.form.meterUser,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterUser", $$v);
      },
      expression: "form.meterUser"
    }
  }), _vm.form.type !== 'waste' ? _c('FormGroup', {
    attrs: {
      "id": "dataCollector",
      "disabled": _vm.disabled,
      "error": _vm.error['dataCollector'],
      "label": "Data Collector"
    },
    model: {
      value: _vm.form.dataCollector,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dataCollector", $$v);
      },
      expression: "form.dataCollector"
    }
  }) : _vm._e(), _vm.form.type === 'electricity' ? _c('FormGroup', {
    attrs: {
      "id": "capacity",
      "disabled": _vm.disabled,
      "error": _vm.error['capacity'],
      "label": "Capacity",
      "type": "text"
    },
    model: {
      value: _vm.form.capacity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "capacity", $$v);
      },
      expression: "form.capacity"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "factor",
      "disabled": _vm.disabled,
      "error": _vm.error['factor'],
      "label": "Correction Factor",
      "type": "number",
      "description": "This will be applied to all consumption calculations. Gas correction factors are automatically applied and should not be set here. Please ensure units are correctly set before using this to avoid factors being applied twice."
    },
    model: {
      value: _vm.form.factor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "factor", $$v);
      },
      expression: "form.factor"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "registerIds",
      "placeholder": "Add a register...",
      "disabled": _vm.disabled,
      "description-position": "top",
      "error": _vm.error['registerIds'],
      "label": "Additional Registers",
      "type": "select-array",
      "description": "As standard there is a single default register (1). Only add additional registers if this meter has more than one register. i.e. night (2)"
    },
    model: {
      value: _vm.form.registerIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "registerIds", $$v);
      },
      expression: "form.registerIds"
    }
  })], 1) : _vm._e(), _vm.selectedCategoryId === 'meterOperator' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_vm.form.type !== 'waste' ? _c('FormGroup', {
    attrs: {
      "id": "meterOperator",
      "disabled": _vm.disabled,
      "error": _vm.error['meterOperator'],
      "label": "Meter Operator Name",
      "type": "text"
    },
    model: {
      value: _vm.form.meterOperator,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterOperator", $$v);
      },
      expression: "form.meterOperator"
    }
  }) : _vm._e(), _vm.form.type !== 'waste' ? _c('FormGroup', {
    attrs: {
      "id": "meterOperatorType",
      "disabled": _vm.disabled,
      "error": _vm.error['meterOperatorType'],
      "label": "Meter Operator Type",
      "type": "select",
      "options": [{
        label: 'Lease',
        value: 'lease'
      }, {
        label: 'Purchase',
        value: 'purchase'
      }]
    },
    model: {
      value: _vm.form.meterOperatorType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterOperatorType", $$v);
      },
      expression: "form.meterOperatorType"
    }
  }) : _vm._e(), _vm.form.type !== 'waste' ? _c('FormGroup', {
    attrs: {
      "id": "meterOperatorExpiryDate",
      "disabled": _vm.disabled,
      "error": _vm.error['meterOperatorExpiryDate'],
      "label": "Meter Operator Expiry Date",
      "type": "datePicker"
    },
    model: {
      value: _vm.form.meterOperatorExpiryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterOperatorExpiryDate", $$v);
      },
      expression: "form.meterOperatorExpiryDate"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.selectedCategoryId === 'building' ? _c('div', {
    staticClass: "col-xl-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "floorArea"
    }
  }, [_vm._v("Floor Area")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    staticClass: "input-group",
    attrs: {
      "id": "floorArea",
      "label": "Floor Area",
      "type": "text"
    },
    model: {
      value: _vm.form.floorArea,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "floorArea", $$v);
      },
      expression: "form.floorArea"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    attrs: {
      "id": "floorAreaUnit",
      "label": "Floor Area Unit",
      "error": _vm.error.floorAreaUnit,
      "options": [{
        value: 'metric',
        label: 'm2'
      }, {
        value: 'imperial',
        label: 'sq. ft'
      }],
      "type": "select",
      "is-alt": true
    },
    model: {
      value: _vm.form.floorAreaUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "floorAreaUnit", $$v);
      },
      expression: "form.floorAreaUnit"
    }
  })], 1)], 1), _c('FormError', {
    attrs: {
      "id": "floorArea",
      "error": (_vm$error$floorArea = _vm.error.floorArea) === null || _vm$error$floorArea === void 0 ? void 0 : _vm$error$floorArea.message
    }
  })], 1), _c('FormGroup', {
    attrs: {
      "id": "meterLocation",
      "disabled": _vm.disabled,
      "error": _vm.error['meterLocation'],
      "label": "Location within building",
      "type": "textarea",
      "rows": "3"
    },
    model: {
      value: _vm.form.meterLocation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "meterLocation", $$v);
      },
      expression: "form.meterLocation"
    }
  })], 1) : _vm._e(), _vm.selectedCategoryId === 'integrations' ? _c('div', {
    staticClass: "col-xl-6"
  }, [!_vm.form.parentAccountId ? _c('div', [_c('label', [_vm._v("Supplier Portal")]), !_vm.form.portal ? _c('a', {
    staticClass: "font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickOverridePortal(true);
      }
    }
  }, [_vm._m(1)]) : _c('div', [_c('a', {
    staticClass: "font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickOverridePortal(false);
      }
    }
  }, [_vm._m(2)]), _c('PortalForm', {
    attrs: {
      "data": _vm.form.portal,
      "error": _vm.error
    },
    on: {
      "update:data": function updateData($event) {
        return _vm.$set(_vm.form, "portal", $event);
      }
    }
  })], 1)]) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "deviceId",
      "disabled": _vm.disabled,
      "error": _vm.error['deviceId'],
      "label": "Device ID",
      "type": "text",
      "description": "The primary identifier used by a third-party system"
    },
    model: {
      value: _vm.form.deviceId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "deviceId", $$v);
      },
      expression: "form.deviceId"
    }
  }), _c('label', [_vm._v("Third Party Identifiers")]), _c('input', {
    class: {
      'is-invalid': _vm.error.thirdParties
    },
    attrs: {
      "id": "thirdParties",
      "type": "hidden"
    }
  }), _c('FormError', {
    staticClass: "mt-0",
    attrs: {
      "id": "thirdParties",
      "error": (_vm$error$thirdPartie = _vm.error.thirdParties) === null || _vm$error$thirdPartie === void 0 ? void 0 : _vm$error$thirdPartie.message
    }
  }), _c('table', {
    staticClass: "table"
  }, [_vm._m(3), _vm._l(_vm.form.thirdParties, function (thirdParty) {
    var _vm$error$thirdPartie2, _vm$error$thirdPartie3, _vm$error$thirdPartie4;

    return _c('tr', {
      key: thirdParty._id,
      class: {
        'text-danger': (_vm$error$thirdPartie2 = _vm.error.thirdParties) === null || _vm$error$thirdPartie2 === void 0 ? void 0 : (_vm$error$thirdPartie3 = _vm$error$thirdPartie2.context) === null || _vm$error$thirdPartie3 === void 0 ? void 0 : (_vm$error$thirdPartie4 = _vm$error$thirdPartie3.duplicates) === null || _vm$error$thirdPartie4 === void 0 ? void 0 : _vm$error$thirdPartie4.includes(thirdParty.name)
      }
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(thirdParty.name))])]), _c('td', [_vm._v(_vm._s(thirdParty.deviceId))]), _c('td', [_c('a', {
      staticClass: "text-danger font-w600",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickDeleteThirdParty(thirdParty);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times fa-lg"
    })])])]);
  })], 2), _c('div', {
    staticClass: "p-3 rounded-md bg-light"
  }, [_c('label', [_vm._v("Add a new Third Party Identifier")]), _c('p', {
    staticClass: "font-size-sm font-w600 text-muted"
  }, [_vm._v(" Select the name of the third party and the ID used to identify this meter. This ID must be unique for the selected third party. ")]), _c('div', {
    staticClass: "row align-items-center no-gutters mb-3"
  }, [_c('div', {
    staticClass: "col-md-6 pr-2"
  }, [_c('FormItem', {
    attrs: {
      "id": "thirdPartyName",
      "type": "select",
      "options": _vm.thirdPartyOptions,
      "placeholder": "Select..."
    },
    model: {
      value: _vm.thirdPartyForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.thirdPartyForm, "name", $$v);
      },
      expression: "thirdPartyForm.name"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4 pr-2"
  }, [_c('FormItem', {
    attrs: {
      "id": "thirdPartyDeviceId",
      "placeholder": "Device ID"
    },
    model: {
      value: _vm.thirdPartyForm.deviceId,
      callback: function callback($$v) {
        _vm.$set(_vm.thirdPartyForm, "deviceId", $$v);
      },
      expression: "thirdPartyForm.deviceId"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.onClickAddThirdParty
    }
  }, [_vm._v("ADD")])])])])], 1) : _vm._e()], 1)]), _c('ConfirmModal', {
    attrs: {
      "id": "add-status",
      "open": !!_vm.modals.status,
      "title": "Change status"
    },
    on: {
      "close": function close($event) {
        _vm.modals.status = false;
      },
      "submit": _vm.onConfirmStatusModal
    }
  }, [_vm.modals.status ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "date",
      "label": "Date",
      "type": "datePicker"
    },
    model: {
      value: _vm.modals.status.date,
      callback: function callback($$v) {
        _vm.$set(_vm.modals.status, "date", $$v);
      },
      expression: "modals.status.date"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "value",
      "label": "Notes",
      "type": "select",
      "options": _vm.modals.status.options
    },
    model: {
      value: _vm.modals.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.modals.status, "value", $$v);
      },
      expression: "modals.status.value"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "note",
      "label": "Notes",
      "type": "textarea"
    },
    model: {
      value: _vm.modals.status.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.modals.status, "notes", $$v);
      },
      expression: "modals.status.notes"
    }
  })], 1) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.deleteStatus,
      "title": "Delete status"
    },
    on: {
      "close": function close($event) {
        _vm.modals.deleteStatus = false;
      },
      "submit": _vm.onConfirmDeleteStatusModal
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Date")]), _c('th', [_vm._v("Value")]), _c('th', [_vm._v("Notes")]), _c('th', {
    staticClass: "text-right"
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded bg-light text-center d-flex align-items-center p-3"
  }, [_c('i', {
    staticClass: "fa fa-triangle-exclamation text-warning fa-2x mr-3"
  }), _c('span', [_vm._v("This account is currently using the supplier portal settings set for your entire company. Click here to override those settings for this account.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded bg-light text-center d-flex align-items-center p-3"
  }, [_c('i', {
    staticClass: "fa fa-triangle-exclamation text-warning fa-2x mr-3"
  }), _c('span', [_vm._v("This account is using custom portal settings. Click here to use company-wide supplier settings for this account instead.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Device ID")]), _c('th')])]);
}]

export { render, staticRenderFns }