<template>
  <div>
    <div class="bg-lighter rounded-md p-4 mb-3">
      <Spinner v-if="loadingAction.integrations" />
      <div v-else>
        <FormGroup
          id="description"
          v-model="form.description"
          label="Description"
          :error="validationErrors.description"
          description="A friendly description of the automation"
        />
        <FormGroup
          id="category"
          v-model="form.category"
          label="Category"
          type="select"
          :options="categoryOptions"
          :error="validationErrors.category"
          description="What category does this automation apply to? For example, if data comes in via a company-wide email receiver then this would be 'Company'. If the automation fetches data for a single meter, then this would be 'Account'."
        />
        <FormGroup
          id="source"
          v-model="form.source"
          label="Source"
          type="select"
          :options="sourceOptions"
          :error="validationErrors.source"
          description="Which source does this data come from?"
        />
        <FormGroup
          id="service"
          v-model="form.service"
          label="File Type or Third Party"
          type="select"
          :options="serviceOptions"
          :error="validationErrors.service"
          placeholder="Select a service..."
        />
        <FormGroup
          id="frequency"
          v-model="form.frequency"
          label="Frequency"
          type="select"
          :options="frequencyOptions"
          :error="validationErrors.frequency"
          description="How often this task is scheduled to run. 'Email Receiver' will run the task when an email is received."
        />

        <FormGroup
          v-if="form.category == 'account'"
          id="accountIds"
          v-model="form.accountIds"
          label="Account"
          type="select-array-search"
          :options="accountOptions"
          :error="validationErrors.accountId"
          placeholder="Select an account..."
          description="Accounts to apply this automation to"
          :action="onSearchInput"
        />
        <FormGroup
          v-if="form.category == 'asset'"
          id="assetId"
          v-model="form.assetId"
          label="Assets"
          type="select-array-list"
          :options="assetOptions"
          :error="validationErrors.assetId"
          placeholder="Select an asset..."
          description="Assets to apply this automation to"
        />
        <FormGroup
          id="active"
          v-model="form.active"
          label="Active"
          type="radio"
          :options="[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]"
        />
      </div>
    </div>

    <div v-if="selectedSourceTemplate?.form" class="bg-lighter rounded-md p-4 mb-3">
      <h4 class="text-capitalize mb-3">{{ sourceOptions.find(source => source.value === form?.source).label }} options</h4>
      <!-- The rest of the form is generated by the template. Templates are currently managed in automation.service.js (API) -->
      <p>{{ selectedSourceTemplate.description }}</p>
      <FormGroup
        v-for="key in Object.keys(selectedSourceTemplate.form)"
        :id="`${form.source}-${key}`"
        :key="`${form.source}-${key}`"
        v-model="form.sourceData[key]"
        :label="selectedSourceTemplate.form[key].label"
        :type="selectedSourceTemplate.form[key].type"
        :description="selectedSourceTemplate.form[key].description"
        :options="selectedSourceTemplate.form[key].options"
        :date-format="selectedSourceTemplate.form[key].dateFormat"
        :placeholder="selectedSourceTemplate.form[key].placeholder"
      />
    </div>

    <div v-if="selectedServiceTemplate?.form" class="bg-lighter rounded-md p-4 mb-3">
      <h4 class="text-capitalize mb-3">{{ integrations.find(integration => integration.service === form.service)?.name }} options</h4>
      <!-- The rest of the form is generated by the template. Templates are currently managed in automation.service.js (API) -->
      <p>{{ selectedServiceTemplate.description }}</p>
      <FormGroup
        v-for="key in Object.keys(selectedServiceTemplate.form)"
        :id="`${form.service}-${key}`"
        :key="`${form.service}-${key}`"
        v-model="form.data[key]"
        :label="selectedServiceTemplate.form[key].label"
        :type="selectedServiceTemplate.form[key].type"
        :description="selectedServiceTemplate.form[key].description"
        :options="selectedServiceTemplate.form[key].options"
        :date-format="selectedServiceTemplate.form[key].dateFormat"
        :placeholder="selectedServiceTemplate.form[key].placeholder"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';
import Spinner from '@/components/Spinner';
import { uniqBy } from 'lodash';

export default {
  name: 'AutomationForm',
  components: {
    FormGroup,
    Spinner
  },
  extends: Form,
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sourceOptions: [
        { label: 'Email', value: 'email' },
        { label: 'S3', value: 's3' },
        { label: 'FTP', value: 'ftp' },
        { label: 'Other', value: 'other' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      validationErrors: 'automation/validationErrors',
      automationTemplates: 'automation/templates',
      accounts: 'account/accounts',
      assets: 'asset/assets',
      searchAccountResults: 'account/searchResults',
      integrations: 'util/integrations',
      loadingAction: 'util/loadingAction'
    }),
    categoryOptions() {
      return [
        { label: 'Account', value: 'account' },
        { label: 'Asset', value: 'asset' },
        { label: 'Company', value: 'company' },
        ...(this.$auth.isAdmin ? [{ label: 'System', value: 'system' }] : [])
      ];
    },
    accountOptions() {
      const accounts = uniqBy(
        [...(this.accounts || []), ...(this.searchAccountResults?.data || [])].map(account => ({
          label: `${account.name} - ${account.meterPointNumber}`,
          value: account._id
        })),
        'value'
      );

      accounts.sort((a, b) => a.label.localeCompare(b.label));

      return accounts;
    },
    assetOptions() {
      const assets = this.assets
        .map(asset => ({
          label: asset.siteName,
          value: asset._id
        }))
        .filter(a => !this.form.assetIds.includes(a.value));

      assets.sort((a, b) => a.label.localeCompare(b.label));

      return assets;
    },
    serviceOptions() {
      if (!this.form.category) return this.integrations?.map(({ service, name }) => ({ label: name, value: service }));
      return this.integrations
        ?.filter(option => option.category === this.form.category)
        .map(({ service, name }) => ({ label: name, value: service }));
    },
    frequencyOptions() {
      return [
        { label: 'Never', value: 'never' },
        { label: 'Daily', value: 'daily' },
        { label: 'Hourly', value: 'hourly' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' }
      ];
    },
    selectedSourceTemplate() {
      if (!this.form?.source) return {};
      return this.automationTemplates.filter(t => t.type === 'source').find(template => template.source === this.form.source);
    },
    selectedServiceTemplate() {
      if (!this.form?.service) return {};
      return this.automationTemplates.filter(t => t.type === 'service').find(template => template.service === this.form.service);
    }
  },
  async mounted() {
    await Promise.all([this.getAssets(), this.getAutomationTemplates(), this.listIntegrations()]);

    // Set Form Defaults
    this.selectedSourceTemplate?.form &&
      Object.entries(this.selectedSourceTemplate.form).forEach(([key, template]) => {
        if (!this.form.sourceData[key] && template.default) {
          this.$set(this.form.sourceData, key, template.default);
        }
      });

    this.selectedServiceTemplate?.form &&
      Object.keys(this.selectedServiceTemplate.form).forEach(key => {
        if (!this.form.data[key] && this.selectedServiceTemplate.form[key].default) {
          this.form.data[key] = this.selectedServiceTemplate.form[key].default;
        }
      });
  },
  methods: {
    ...mapActions({
      getAutomationTemplates: 'automation/getTemplates',
      listIntegrations: 'util/listIntegrations',
      getAssets: 'asset/list',
      getAccounts: 'account/list',
      searchAccounts: 'account/search'
    }),
    async onSearchInput(query) {
      await this.searchAccounts({ data: { params: { query } } });
    }
  }
};
</script>
