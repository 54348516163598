<template>
  <div>
    <PageHeaderDisplay
      :title="capitalize(importType)"
      :sub-title="`Import ${capitalize(importType)} data from a CSV file directly into Etainabl by following the steps below`"
      category="Import Management"
      category-logo="fa-file-import"
      :category-route="{ name: 'import-list' }"
    />
    <div class="content">
      <div class="row mb-4">
        <div class="col">
          <div
            class="wizard-step"
            :class="{
              active: $route.name === 'import-upload',
              completed: completed.upload
            }"
          >
            <div class="text-muted text-uppercase font-w600">
              Step 1 <i v-if="completed.upload" class="fa-duotone fa-circle-check text-success mr-2"></i>
            </div>
            <strong class="font-size-h4"><i class="fa-duotone fa-upload mr-2"></i>Upload</strong>
          </div>
        </div>
        <div class="col">
          <div
            class="wizard-step"
            :class="{
              active: $route.name === 'import-mapping',
              completed: completed.mapping
            }"
          >
            <div class="text-muted text-uppercase font-w600">
              Step 2 <i v-if="completed.mapping" class="fa-duotone fa-circle-check text-success mr-2"></i>
            </div>
            <strong class="font-size-h4"><i class="fa-duotone fa-diagram-next mr-2"></i>Mapping</strong>
          </div>
        </div>
        <div class="col">
          <div class="wizard-step" :class="{ active: $route.name === 'import-preview', completed: completed.preview }">
            <div class="text-muted text-uppercase font-w600">
              Step 3 <i v-if="completed.preview" class="fa-duotone fa-circle-check text-success mr-2"></i>
            </div>
            <strong class="font-size-h4"><i class="fa-duotone fa-magnifying-glass mr-2"></i>Preview</strong>
          </div>
        </div>
        <div class="col">
          <div class="wizard-step" :class="{ active: $route.name === 'import-import' }">
            <div class="text-muted text-uppercase font-w600">
              Step 4 <i v-if="completed.import" class="fa-duotone fa-circle-check text-success mr-2"></i>
            </div>
            <strong class="font-size-h4"><i class="fa-duotone fa-file-import mr-2"></i>Import</strong>
          </div>
        </div>
      </div>
      <router-view :key="importData._id" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import { capitalize } from '@/lib/helpers';

export default {
  name: 'ImportWizard',
  components: {
    PageHeaderDisplay
  },
  data() {
    return {
      steps: [
        {
          name: 'upload',
          title: 'Upload',
          route: 'import-upload'
        },
        {
          name: 'mapping',
          title: 'Mapping',
          route: 'import-mapping'
        },
        {
          name: 'preview',
          title: 'Preview',
          route: 'import-preview'
        },
        {
          name: 'import',
          title: 'Import',
          route: 'import-import'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      setup: 'import/setup',
      importData: 'import/import',
      importTemplate: 'import/importTemplate',
      errorAction: 'import/errorAction',
      loadingAction: 'import/loadingAction'
    }),
    completed() {
      return {
        upload: ['import-mapping', 'import-preview', 'import-import'].includes(this.$route.name),
        mapping: ['import-preview', 'import-import'].includes(this.$route.name),
        preview: ['import-import'].includes(this.$route.name)
      };
    },
    importType() {
      if (this.importData?.importTemplate?.name) {
        return this.importData?.importTemplate?.name;
      }

      if (this.importTemplate?.name) {
        return this.importTemplate?.name;
      }

      if (this.importData?.importTemplateId) {
        return 'Template Import';
      }

      return `${this.importData.type || this.$route.query.type} Import`;
    }
  },
  methods: {
    capitalize
  }
};
</script>
<style lang="scss" scoped>
@import '~@/assets/_scss/custom/variables';

.wizard-step {
  text-align: center;
  padding-bottom: 10px;
}

.wizard-step::after {
  background: #eee;
  border-radius: 6px;
  content: '';
  width: calc(100% + 3px);
  bottom: -2px;
  position: absolute;
  height: 2px;
  left: 0;
  padding: 3px 0;
}

.wizard-step.active::after {
  z-index: 1;
  background: $primary;
}

.wizard-step.completed::after {
  z-index: 2;
  background: lighten($primary, 30%);
}
</style>
