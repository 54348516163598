<template>
  <div>
    <PageHeaderDisplay
      sub-title="Further details and actions regarding the selected import"
      category="Import Management"
      category-logo="fa-file-import"
      :category-route="{ name: 'import-list' }"
    >
      <span v-if="importItem._id">{{ importItem.type | capitalize }} Import - {{ importItem.createdAt | date('DD/MM/YYYY HH:mm:ss') }}</span
      ><span v-else>Import Details</span>
    </PageHeaderDisplay>

    <div class="content">
      <div v-if="loading">
        <Spinner />
      </div>
      <div v-else-if="!importItem">
        <div class="alert alert-warning">No import found.</div>
      </div>
      <div v-else>
        <div class="mb-5">
          <SectionTitle icon="fa-search">Details</SectionTitle>
          <div class="row">
            <div class="col-md-6">
              <PropertyListItem class="mb-2 text-capitalize" title="Status"
                ><strong>{{ importItem.status }}</strong></PropertyListItem
              >
              <PropertyListItem class="mb-2" title="Start Date">{{ importItem.createdAt | date('DD/MM/YYYY HH:mm:ss') }}</PropertyListItem>
              <PropertyListItem class="mb-2" title="Imported By">{{ importItem.userSub | user(users) }}</PropertyListItem>
              <PropertyListItem class="mb-2 text-capitalize" title="Type">{{ importItem.type }}</PropertyListItem>
              <PropertyListItem v-if="importItem.importTemplate" class="mb-2 text-capitalize" title="Template">{{
                importItem.importTemplate.name
              }}</PropertyListItem>
              <PropertyListItem class="mb-2 text-capitalize" title="Items Imported">{{
                Number.isInteger(importItem.count) ? importItem.count : 'N/A'
              }}</PropertyListItem>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <SectionTitle icon="fa-clock-rotate-left">Rollback</SectionTitle>
          <div v-if="importItem.status === 'imported' || importItem.status === 'partial-import'">
            <p>
              <strong>Notes:</strong> Rolling back this import will also delete all associated data related to the imported data. For example, if the
              import included an asset, and an account was created under that asset, the account will be deleted along with the asset.
            </p>
            <p>Check over all the data below to fully understand what data is going to be deleted.</p>
            <div class="d-flex justify-content-center">
              <button class="btn btn-danger btn-lg" :class="{ disabled: loadingAction.rollback }" @click="onClickRollback">
                <span v-if="loadingAction.rollback"><i class="fa fa-spin fa-fw fa-circle-notch"></i> Rolling back...</span
                ><span v-else>Start Rollback</span>
              </button>
            </div>
          </div>
          <div v-else-if="importItem.status === 'rolledback'">
            <div class="d-flex align-items-center alert alert-warning mb-3">
              <i class="fa fa-circle-info fa-xl mr-4"></i>
              <div>This import has been rolled back.</div>
            </div>
          </div>
          <div v-else>
            <div class="d-flex align-items-center alert alert-danger mb-3">
              <i class="fa fa-exclamation-triangle fa-xl mr-4"></i>
              <div>This import can't be rolled back.</div>
            </div>
          </div>
        </div>

        <div>
          <SectionTitle icon="fa-list">Data</SectionTitle>
          <div v-if="loadingAction.getImportedItems">
            <Spinner />
          </div>
          <div v-else>
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Item</th>
                  <th>Deleted</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in parsedImportedItems" :key="item._id">
                  <td class="font-w600">{{ item.importItemTitle }}</td>
                  <td>{{ item.deleted ? 'Yes' : 'No' }}</td>
                  <td>
                    <router-link
                      v-if="importItem.type === 'asset'"
                      class="btn btn-sm btn-primary"
                      :class="{ disabled: item.deleted }"
                      target="_blank"
                      :to="{ name: `asset-view`, params: { id: item._id } }"
                      >View</router-link
                    >
                    <router-link
                      v-else-if="importItem.type === 'contract'"
                      class="btn btn-sm btn-primary"
                      :class="{ disabled: item.deleted }"
                      target="_blank"
                      :to="{ name: `asset-accounts-contracts`, params: { id: item.asset._id, accountId: item.account._id } }"
                      >View</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmModal id="rollback" :open="modals.rollback" title="Rollback Import" @close="modals.rollback = false" @submit="onConfirmRollback">
        <p>Please confirm you would like to rollback this import.</p>
      </ConfirmModal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import PropertyListItem from '@/components/base/PropertyListItem';
import SectionTitle from '@/components/base/SectionTitle';
import Spinner from '@/components/Spinner';

import { niceDate } from '@/lib/helpers';

export default {
  name: 'ImportRollback',
  components: {
    ConfirmModal,
    PageHeaderDisplay,
    PropertyListItem,
    SectionTitle,
    Spinner
  },
  data() {
    return {
      modals: {
        rollback: false
      },
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      users: 'company/users',
      importItem: 'import/import',
      importedItems: 'import/importedItems',
      setup: 'import/setup',
      errorAction: 'import/errorAction',
      loadingAction: 'import/loadingAction'
    }),
    parsedImportedItems() {
      return this.importedItems.map(item => {
        let importItemTitle = item.name;

        if (this.importItem.type === 'asset') {
          importItemTitle = item.siteName;
        } else if (this.importItem.type === 'contract') {
          importItemTitle = `Contact with start date ${niceDate(item.startDate)} for MPAN ${item.account.meterPointNumber}`;
        } else if (this.importItem.type === 'reading') {
          importItemTitle = `Reading ${item.value} at ${niceDate(item.submittedAt, 'DD/MM/YYYY HH:mm:ss')} for MPAN ${item.account.meterPointNumber}${
            item.subMeter ? ` and sub meter ${item.subMeter.meterSerialNumber}` : ''
          }`;
        } else if (this.importItem.type === 'account') {
          importItemTitle = `Account with MPAN ${item.meterPointNumber}`;
        }

        return {
          ...item,
          importItemTitle
        };
      });
    }
  },
  async mounted() {
    if (!this.$route.params.id) {
      return this.$router.push({ name: 'import-list' });
    }

    if (this.users.length === 0) {
      await this.listUsers({ id: this.$auth.companyId });
    }

    await this.getImport({ id: this.$route.params.id, data: { params: { $populate: 'importTemplate' } } });

    this.loading = false;

    await this.getImportedItems({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getImport: 'import/get',
      getImportedItems: 'import/getImportedItems',
      listUsers: 'company/listUsers',
      rollbackImport: 'import/rollback'
    }),
    onClickRollback() {
      this.modals.rollback = true;
    },
    async onConfirmRollback() {
      this.modals.rollback = false;
      await this.rollbackImport({ id: this.importItem._id });
      await this.getImportedItems({ id: this.$route.params.id });
    }
  }
};
</script>
