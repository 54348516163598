var render = function () {
  var _vm$modal$details$att;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Activity Log",
      "sub-title": "A log of all activity within Etainabl",
      "category": "Company",
      "category-logo": "fa-users"
    }
  }), _c('div', {
    staticClass: "content content-full"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "date",
      "label": "Date Range",
      "type": "dateRangePicker"
    },
    on: {
      "input": _vm.onDateInput
    },
    model: {
      value: _vm.options.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "dateRange", $$v);
      },
      expression: "options.dateRange"
    }
  })], 1)])]), _c('DataTable', {
    attrs: {
      "items": _vm.searchResults.data,
      "loading": _vm.logsLoading.search,
      "columns": _vm.columns,
      "page-size": 100,
      "fetch-items": _vm.refresh,
      "other-params": _vm.otherParams,
      "default-sort-key": "createdAt",
      "default-sort-direction": -1,
      "pagination": _vm.searchResults.pagination,
      "top-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "createdAt",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [_c('span', {
          staticClass: "font-w600 no-wrap"
        }, [_vm._v(_vm._s(_vm._f("date")(data.createdAt, 'Do MMM YYYY HH:mm:ss')))])];
      }
    }, {
      key: "type",
      fn: function fn(_ref2) {
        var data = _ref2.data;
        return [_vm._v(" " + _vm._s(_vm.typeMap[data.type] || data.type) + " ")];
      }
    }, {
      key: "userSub",
      fn: function fn(_ref3) {
        var data = _ref3.data;
        return [_vm._v(" " + _vm._s(_vm._f("user")(data.userSub, _vm.users)) + " ")];
      }
    }, {
      key: "context",
      fn: function fn(_ref4) {
        var data = _ref4.data;
        return [_c('a', {
          staticClass: "btn btn-link font-w600 no-wrap",
          on: {
            "click": function click($event) {
              _vm.modal.details = data;
            }
          }
        }, [_vm._v("More Details")])];
      }
    }])
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modal.details,
      "title": "Log Details",
      "lg-size": "",
      "hide-submit": ""
    },
    on: {
      "close": function close() {
        _vm.modal.details = false;
      }
    }
  }, [_c('p', {
    staticClass: "font-w600"
  }, [_vm._v(_vm._s(_vm.modal.details.message))]), _c('div', {
    staticClass: "mb-4"
  }, [_c('PropertyListItem', {
    attrs: {
      "title": "Date"
    }
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.modal.details.createdAt, 'Do MMMM YYYY')))]), _c('PropertyListItem', {
    attrs: {
      "title": "Time"
    }
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.modal.details.createdAt, 'HH:mm:ss')))]), _c('PropertyListItem', {
    attrs: {
      "title": "Type"
    }
  }, [_vm._v(_vm._s(_vm.typeMap[_vm.modal.details.type] || _vm.modal.details.type))]), _c('PropertyListItem', {
    attrs: {
      "title": "User"
    }
  }, [_vm._v(_vm._s(_vm._f("user")(_vm.modal.details.userSub, _vm.users)))]), (_vm$modal$details$att = _vm.modal.details.attachment) !== null && _vm$modal$details$att !== void 0 && _vm$modal$details$att.s3Key ? _c('PropertyListItem', {
    attrs: {
      "title": "Attachment"
    }
  }, [_c('a', {
    staticClass: "font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickDownloadAttachment(_vm.modal.details);
      }
    }
  }, [_vm._v("Download " + _vm._s(_vm.modal.details.attachment.fileName || _vm.modal.details.attachment.s3Key.split('/').pop()))])]) : _vm._e()], 1), _c('p', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("Additional Data")]), _c('pre', {
    staticClass: "pre--dark p-3"
  }, [_vm._v(_vm._s(_vm.modal.details.context))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }