import actions from './actions';
import mutations from './mutations';

const initialState = () => {
  return {
    loading: false,
    loadingAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {})
    },
    logs: [],
    total: 0,
    searchResults: {
      data: [],
      pagination: {
        limit: 0,
        skip: 0,
        total: 0,
        token: null,
        page: 1,
        pages: 1
      }
    }
  };
};

const store = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => state.loading,
    loadingAction: state => state.loadingAction,
    logs: state => state.logs,
    total: state => state.total,
    errorAction: state => state.errorAction,
    searchResults: state => state.searchResults
  },
  mutations: {
    CLEAR_STORE: state => {
      Object.assign(state, initialState());
    },
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
