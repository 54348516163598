<template>
  <div class="container">
    <div v-if="loading">
      <Spinner />
    </div>
    <div v-else>
      <div v-if="results.error">
        <div class="alert alert-danger">{{ results.message }}</div>
        <table v-if="Array.isArray(results.data)" class="table">
          <thead>
            <tr>
              <th>Row</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="error in results.data.filter(r => r.errors.length > 0)" :key="error.row">
              <td class="font-w600">{{ error.row }}</td>
              <td class="text-danger">{{ error.errors.join(', ') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="alreadyImported">
        <div class="alert alert-warning">This file has already been imported.</div>
      </div>
      <div v-else>
        <div v-if="results.error === false" class="alert alert-success">
          {{ results.message }}
        </div>
        <table v-if="Array.isArray(results.data)" class="table">
          <thead>
            <tr>
              <th>Row</th>
              <th v-if="primaryField">{{ primaryField.friendly }}</th>
              <th>Imported</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results.data" :key="item._id">
              <td class="font-w600">{{ index + 1 }}</td>
              <td v-if="primaryField">{{ item[primaryField.key] }}</td>
              <td><i class="fad fa-circle-check fa-lg text-success"></i></td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <div></div>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <router-link :to="{ name: 'import-list' }" class="btn btn-light">Start Over</router-link>
        <router-link :to="{ name: 'import-detail', params: { id: importItem._id } }" class="btn btn-primary">View Import</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Spinner from '@/components/Spinner';

export default {
  name: 'ImportImport',
  components: {
    Spinner
  },
  data() {
    return {
      alreadyImported: false,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      importItem: 'import/import',
      setup: 'import/setup',
      mapping: 'import/mapping',
      results: 'import/results',
      errorAction: 'import/errorAction',
      loadingAction: 'import/loadingAction'
    }),
    primaryField() {
      const key = Object.keys(this.setup.info.fields).find(k => this.setup.info.fields[k].primary);

      if (key) {
        return {
          key,
          ...this.setup.info.fields[key]
        };
      }

      return null;
    }
  },
  async mounted() {
    if (!this.$route.params.id) {
      return this.$router.push({ name: 'import-list' });
    }

    await this.getImport({ id: this.$route.params.id, data: { params: { $populate: 'importTemplate' } } });

    if (this.importItem.mapping.length === 0) {
      return this.$router.push({ name: 'import-mapping', query: { type: this.importItem.type }, params: { id: this.importItem._id } });
    }

    if (!this.setup.info) {
      await this.getInfo({ type: this.importItem.type });
    }

    if (this.importItem.status === 'mapped') {
      await this.postImport({ id: this.importItem._id });
    } else if (this.importItem.status === 'imported' || this.importItem.status === 'partial-import') {
      this.alreadyImported = true;
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getInfo: 'import/getInfo',
      getImport: 'import/get',
      postImport: 'import/postImport'
    }),
    ...mapMutations({
      setId: 'import/SET_ID'
    }),
    onSubmit() {
      this.$router.push({ name: 'import-list' });
    }
  }
};
</script>
