var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "bg-light rounded-md p-4"
  }, [_vm._m(1), !_vm.template.singleSource ? _c('FormGroup', {
    attrs: {
      "id": "portfolios",
      "options": _vm.assetGroupOptions,
      "description": "Select a portfolio to automatically select all assets within it",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "label": "Portfolios",
      "placeholder": "Select a portfolio"
    },
    on: {
      "input": _vm.onSelectPortfolio
    }
  }) : _vm._e(), _vm.tree.loading ? _c('SpinnerLogo') : _c('div', [_c('FormItem', {
    staticClass: "mb-2",
    attrs: {
      "id": "tree-search",
      "name": "tree-search",
      "placeholder": "Search..."
    },
    on: {
      "input": _vm.onSearch
    },
    model: {
      value: _vm.tree.search.query,
      callback: function callback($$v) {
        _vm.$set(_vm.tree.search, "query", $$v);
      },
      expression: "tree.search.query"
    }
  }), _vm.tree.search.loading ? _c('Spinner', {
    staticClass: "mt-5",
    attrs: {
      "size": "sm"
    }
  }) : _c('div', {
    staticClass: "Tree"
  }, _vm._l(_vm.treeItems, function (item) {
    return _c('div', {
      key: item.value,
      staticClass: "Tree-item"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between bg-white my-2 rounded-sm font-w500",
      class: {
        selected: _vm.form.sourceIds.some(function (s) {
          return s._id === item.value;
        }),
        'selected--partial': _vm.form.sourceIds.some(function (s) {
          return s.parentId === item.value;
        }) && !item.children.every(function (c) {
          return _vm.form.sourceIds.some(function (s) {
            return s._id === c.value;
          });
        })
      }
    }, [_c('div', {
      staticClass: "d-flex flex-grow-1 align-items-center"
    }, [!_vm.hideAssetExpand ? _c('div', {
      staticClass: "Tree-expander pl-2 py-2",
      on: {
        "click": function click($event) {
          return _vm.onClickTreeItem(item);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw mr-2",
      class: item.expanded ? 'fa-minus' : 'fa-plus'
    })]) : _vm._e(), _c('div', {
      staticClass: "Tree-label flex-grow-1 pl-1 pr-2 py-2",
      class: {
        'pl-2': _vm.hideAssetExpand
      },
      on: {
        "click": function click($event) {
          return _vm.onSelectTreeItem(item);
        }
      }
    }, [_c('div', [_c('i', {
      staticClass: "fa fa-building text-primary fa-fw fa-sm mr-1"
    }), _vm._v(_vm._s(item.label))]), _c('div', [_vm._v(" " + _vm._s(item.subLabel) + " ")])]), item.loading ? _c('div', {
      staticClass: "pr-2 py-2"
    }, [_c('i', {
      staticClass: "fa fa-spin fa-circle-notch"
    })]) : _vm._e()])]), item.expanded ? _c('div', [_vm._l(item.children, function (childItem) {
      return _c('div', {
        key: childItem.value,
        staticClass: "Tree-item my-2 ml-4"
      }, [_c('div', {
        staticClass: "bg-white Tree-label d-flex align-items-center rounded-sm font-size-sm p-2",
        class: {
          selected: _vm.form.sourceIds.some(function (s) {
            return s._id === item.value;
          }) || _vm.form.sourceIds.some(function (s) {
            return s._id === childItem.value;
          })
        },
        on: {
          "click": function click($event) {
            return _vm.onSelectTreeItem(childItem);
          }
        }
      }, [_c('div', [_c('UtilityTypeIcon', {
        attrs: {
          "type": childItem.utilityType,
          "text": false,
          "icon-class": "fa text-primary"
        }
      })], 1), _c('div', [_c('div', {
        staticClass: "font-w600"
      }, [_vm._v(" " + _vm._s(childItem.label) + " ")]), _c('span', [_vm._v(_vm._s(childItem.subLabel))])])]), _vm._l(childItem.children, function (grandChildItem) {
        return _c('div', {
          key: grandChildItem.value,
          staticClass: "Tree-item my-2 ml-4"
        }, [_c('div', {
          staticClass: "bg-white Tree-label d-flex align-items-center rounded-sm font-size-sm p-2",
          class: {
            selected: _vm.form.sourceIds.some(function (s) {
              return s._id === item.value;
            }) || _vm.form.sourceIds.some(function (s) {
              return s._id === grandChildItem.value;
            })
          },
          on: {
            "click": function click($event) {
              return _vm.onSelectTreeItem(grandChildItem);
            }
          }
        }, [_c('div', [_c('UtilityTypeIcon', {
          attrs: {
            "type": grandChildItem.utilityType,
            "text": false,
            "icon-class": "fa text-primary"
          }
        })], 1), _c('div', [_c('div', {
          staticClass: "font-w600"
        }, [_vm._v(" " + _vm._s(grandChildItem.label) + " ")]), _c('span', [_vm._v(_vm._s(grandChildItem.subLabel))])])])]);
      })], 2);
    }), !item.loading && item.children.length === 0 ? _c('div', {
      staticClass: "alert alert-warning py-1 px-2 mb-0 ml-4"
    }, [_c('i', {
      staticClass: "fa fa-exclamation-circle fa-fw mr-2"
    }), _vm._v("No accounts found ")]) : _vm._e()], 2) : _vm._e()]);
  }), 0)], 1)], 1)]), _c('div', {
    staticClass: "col-lg-7"
  }, [!_vm.isDynamic && _vm.template ? _c('div', [_c('SectionTitle', [_vm._v("Generating report: " + _vm._s(_vm.template.name))]), _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.template.description))])], 1) : _vm.isDynamic ? _c('div', [_c('SectionTitle', [_vm._v("Dynamic Report")]), _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" Build your own report with dates, headings and data types of your choice. If you plan to re-use your report in the future, you can save it as a template. ")])], 1) : _vm._e(), _c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_vm._m(2), _vm.selectedSources.assets.length > 0 ? _c('div', [_vm.selectedAssetGroup ? _c('div', {
    staticClass: "bg-white p-2 rounded-sm mb-2 d-flex selected--portfolio"
  }, [_vm._m(3), _c('div', [_c('div', {
    staticClass: "font-w600"
  }, [_vm._v(_vm._s(_vm.selectedAssetGroup.name))]), _c('div', {
    staticClass: "font-size-sm"
  }, [_vm._v("All Assets & Accounts")])])]) : _vm._l(_vm.selectedSources.assets, function (source) {
    return _c('div', {
      key: source._id,
      staticClass: "bg-white p-2 rounded-sm mb-2 d-flex",
      class: {
        'selected--partial': _vm.selectedSources.accounts.filter(function (s) {
          return s.assetId === source.value;
        }),
        selected: source.isSelected
      }
    }, [_vm._m(4, true), _c('div', [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(source.label))]), source.isSelected && !_vm.template.strictSource ? _c('div', {
      staticClass: "font-size-sm"
    }, [_vm._v("All Accounts")]) : _c('div', _vm._l(_vm.selectedSources.accounts.filter(function (s) {
      return s.assetId === source.value;
    }), function (subSource) {
      return _c('div', {
        key: subSource.value
      }, [_c('div', {
        staticClass: "my-1 font-size-sm"
      }, [_c('UtilityTypeIcon', {
        attrs: {
          "type": subSource.type,
          "text": false,
          "icon-class": "fa text-primary"
        }
      }), _vm._v(_vm._s(subSource.label) + " ")], 1)]);
    }), 0)])]);
  })], 2) : _c('div', [_vm._m(5)])]), _vm.isDynamic ? _c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_vm._m(6), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-6"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "subType",
      "label": "",
      "type": "select",
      "options": _vm.subTypeOptions
    },
    on: {
      "input": function input() {
        return _vm.onSelectType();
      }
    },
    model: {
      value: _vm.form.subType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subType", $$v);
      },
      expression: "form.subType"
    }
  })], 1)])]) : _vm._e(), _c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_c('div', {
    staticClass: "border-bottom mb-4 pb-1"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.isDynamic ? 3 : 2) + ". Settings")])]), _vm.displayCheck.dates ? _c('div', {
    staticClass: "row mb-3 border-bottom pb-4"
  }, [_c('div', {
    staticClass: "col-xl-6 col-lg-6"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "dateRange",
      "label": "Date Range",
      "type": "dateRangePicker",
      "config": {
        utc: true,
        time: true
      }
    },
    on: {
      "input": _vm.onSelectDateRange
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_vm.displayCheck.granularity ? _c('div', {
    staticClass: "col-xl-3"
  }, [_c('FormGroup', {
    key: _vm.template._id,
    staticClass: "mb-0",
    attrs: {
      "id": "granularity",
      "disabled": _vm.form.subType === 'dataQuality',
      "label": "Granularity",
      "type": "select",
      "options": _vm.granularityOptions
    },
    model: {
      value: _vm.form.granularity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "granularity", $$v);
      },
      expression: "form.granularity"
    }
  })], 1) : _vm._e(), _vm.displayCheck.consumptionSource ? _c('div', {
    staticClass: "col-xl-4"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "consumptionSource",
      "label": "Consumption Data Source",
      "type": "select",
      "options": _vm.consumptionSourceOptions
    },
    model: {
      value: _vm.form.consumptionSource,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "consumptionSource", $$v);
      },
      expression: "form.consumptionSource"
    }
  })], 1) : _vm._e(), _vm.displayCheck.rawUnits ? _c('div', {
    staticClass: "col-xl-4"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "rawUnits",
      "label": "Units",
      "type": "select",
      "options": _vm.rawUnitOptions
    },
    model: {
      value: _vm.form.rawUnits,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rawUnits", $$v);
      },
      expression: "form.rawUnits"
    }
  })], 1) : _vm._e(), _vm.displayCheck.fileFormat ? _c('div', {
    staticClass: "col-xl-3"
  }, [_c('FormGroup', {
    key: _vm.template._id,
    staticClass: "mb-0",
    attrs: {
      "id": "fileFormat",
      "label": "Format",
      "type": "select",
      "placeholder": "Select a file format...",
      "options": _vm.fileFormatOptions
    },
    model: {
      value: _vm.form.fileFormat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fileFormat", $$v);
      },
      expression: "form.fileFormat"
    }
  })], 1) : _vm._e()]), _vm.displayCheck.dateField || _vm.displayCheck.showSimulated ? _c('div', {
    staticClass: "row mt-3 border-top pt-4"
  }, [_vm.displayCheck.showSimulated ? _c('div', {
    staticClass: "col-xl-3"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "showSimulated",
      "label": "Show Simulated Data",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }]
    },
    model: {
      value: _vm.form.showSimulated,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "showSimulated", $$v);
      },
      expression: "form.showSimulated"
    }
  })], 1) : _vm._e(), _vm.displayCheck.dateField ? _c('div', {
    staticClass: "col-xl-4"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "dateField",
      "label": "Date Field",
      "description": "The invoice field to apply the above date range too",
      "type": "select",
      "options": _vm.invoiceDateFieldOptions
    },
    model: {
      value: _vm.form.dateField,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dateField", $$v);
      },
      expression: "form.dateField"
    }
  })], 1) : _vm._e()]) : _vm._e()]), _vm.displayCheck.chartSettings && _vm.form.fileFormat === 'chart' ? _c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_c('div', {
    staticClass: "border-bottom mb-4 pb-1"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.isDynamic ? 4 : 3) + ". Chart Settings")])]), _vm._l(_vm.chartGenerationOptions, function (option) {
    return _c('div', {
      key: option.name
    }, [option.type === 'buttons' ? _c('div', {
      staticClass: "d-flex align-items-center mr-3 pr-3"
    }, _vm._l(option.items, function (item) {
      return _c('a', {
        key: item.value,
        staticClass: "font-w600 px-3 py-1",
        class: {
          'bg-primary text-white border rounded-md': _vm.form.chartOptions[option.name] === item.value
        },
        attrs: {
          "href": "#"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            _vm.form.chartOptions[option.name] = item.value;
          }
        }
      }, [_c('i', {
        class: "fa-solid ".concat(item.icon, " mr-1")
      }), _vm._v(" " + _vm._s(item.label))]);
    }), 0) : _c('div', [_c('FormGroup', {
      staticClass: "mb-0",
      attrs: {
        "id": option.name,
        "label": option.label,
        "type": option.type,
        "options": option.items
      },
      model: {
        value: _vm.form.chartOptions[option.name],
        callback: function callback($$v) {
          _vm.$set(_vm.form.chartOptions, option.name, $$v);
        },
        expression: "form.chartOptions[option.name]"
      }
    })], 1)]);
  })], 2) : _vm._e(), _vm.isDynamic ? _c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_c('h5', [_vm._v(_vm._s(_vm.isDynamic ? 4 : 3) + ". Headers/Fields")]), _vm.schemaLoading ? _c('SpinnerLogo') : _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "source",
      "label": "",
      "options": _vm.schemaItems,
      "type": "multi-check-options",
      "alt": ""
    },
    model: {
      value: _vm.form.schema,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "schema", $$v);
      },
      expression: "form.schema"
    }
  })], 1) : _vm._e(), _vm.displayCheck ? _c('div', {
    key: _vm.form.subType,
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_vm._m(7), _c('div', {
    staticClass: "row mb-3"
  }, [_vm.displayCheck.accountTags ? _c('div', {
    staticClass: "col-xl-3"
  }, [_c('FormGroup', {
    attrs: {
      "id": "accountTags",
      "placeholder": "Select a tag...",
      "label": "Account Tag",
      "type": "select",
      "description": "Filter accounts by a tag",
      "options": _vm.accountTags.map(function (tag) {
        return {
          label: tag,
          value: tag
        };
      })
    },
    model: {
      value: _vm.form.accountTag,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "accountTag", $$v);
      },
      expression: "form.accountTag"
    }
  })], 1) : _vm._e(), _vm.displayCheck.utilityType ? _c('div', {
    staticClass: "col-xl-3"
  }, [_c('FormGroup', {
    staticClass: "mb-3",
    attrs: {
      "id": "utilityType",
      "label": "Utility Type",
      "placeholder": "All",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "options": _vm.utilityTypeOptions
    },
    model: {
      value: _vm.form.utilityType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "utilityType", $$v);
      },
      expression: "form.utilityType"
    }
  })], 1) : _vm._e(), _vm.displayCheck.includeSubMeters ? _c('div', {
    staticClass: "col-xl-3"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "includeSubMeters",
      "label": "Include Sub Meters",
      "type": "select",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }]
    },
    model: {
      value: _vm.form.includeSubMeters,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "includeSubMeters", $$v);
      },
      expression: "form.includeSubMeters"
    }
  })], 1) : _vm._e(), _vm.displayCheck.readingSource ? _c('div', {
    staticClass: "col-xl-3"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "readingSource",
      "label": "Source",
      "placeholder": "All",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "options": [{
        label: 'Manual',
        value: 'manual'
      }, {
        label: 'Invoice',
        value: 'invoice'
      }, {
        label: 'Import',
        value: 'import'
      }]
    },
    model: {
      value: _vm.form.readingSource,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "readingSource", $$v);
      },
      expression: "form.readingSource"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col"
  }, [!_vm.generating ? _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": !_vm.isAllowedGenerate
    },
    on: {
      "click": _vm.onClickGenerate
    }
  }, [_c('i', {
    staticClass: "fa fa-gear mr-1"
  }), _vm._v(" Generate ")]) : _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": !_vm.isAllowedGenerate
    }
  }, [_c('i', {
    staticClass: "fa fa-gear mr-1 fa-spin"
  }), _vm._v(" Generating... ")])]), _c('div', {
    staticClass: "col-xl-4"
  }, [_c('button', {
    staticClass: "btn btn-warning btn-block",
    attrs: {
      "disabled": !_vm.isAllowedGenerate
    },
    on: {
      "click": function click($event) {
        _vm.templateModal = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save mr-1"
  }), _vm._v(" Save as template ")])])])]) : _vm._e(), _c('ConfirmModal', {
    attrs: {
      "loading": _vm.templateLoadingAction.create,
      "open": !!_vm.templateModal,
      "title": "Save As Template"
    },
    on: {
      "close": function close($event) {
        _vm.templateModal = false;
      },
      "submit": _vm.onCreateTemplate
    }
  }, [_c('ReportTemplateForm', {
    model: {
      value: _vm.templateForm,
      callback: function callback($$v) {
        _vm.templateForm = $$v;
      },
      expression: "templateForm"
    }
  })], 1)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-info"
  }, [_c('strong', [_c('i', {
    staticClass: "fa fa-flask mr-1"
  }), _vm._v("EXPERIMENTAL")]), _vm._v(" This feature was recently added and may have limited functionality. Report any issues or feedback using the question mark at the bottom right. ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-bottom mb-4 pb-1"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("1. Data Selection")]), _c('p', {
    staticClass: "font-size-sm mb-0"
  }, [_vm._v("Narrow down your report to the below sets of data.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-bottom mb-4 pb-1"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Selected Data")]), _c('p', {
    staticClass: "font-size-sm mb-3"
  }, [_vm._v("The report will be run for the below assets & meters:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pr-2 pt-1"
  }, [_c('i', {
    staticClass: "fa fa-buildings fa-fw text-primary"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pr-2"
  }, [_c('i', {
    staticClass: "fa fa-building fa-fw text-primary"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-warning mb-0"
  }, [_c('i', {
    staticClass: "fa fa-exclamation-circle fa-fw mr-2"
  }), _vm._v("No data selected")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-bottom mb-4 pb-1"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("2. Report Type")]), _c('p', {
    staticClass: "font-size-sm mb-0"
  }, [_vm._v("Select the type of data to show on each row.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-bottom mb-4 pb-1"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Filters")])]);
}]

export { render, staticRenderFns }