import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/vue';
import { uniqBy as _uniqBy } from 'lodash';
// import { initSockets } from '../socket';
/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

export const useAuth0 = ({ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, redirectUri = window.location.origin, ...options }) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        isKiosk: false,
        kioskToken: null,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        cacheLocation: options.cacheLocation,
        redirect_uri: redirectUri
      });

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);

          Vue.mixpanel.track('User Login');
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();

        if (this.user) {
          this.isAdmin = this.user['https://app.etainabl.com/isAdmin'];
          this.companyId = this.user['https://app.etainabl.com/companyId'];
          this.entityId = this.user['https://app.etainabl.com/entityId'];
          this.apiKey = this.user['https://app.etainabl.com/apiKey'];
          this.roles = this.user['https://app.etainabl.com/roles'];
          this.companies = this.user['https://app.etainabl.com/companies'];

          this.isEntityGroup = this.roles.some(role => role.startsWith('entity'));

          const token = await this.auth0Client.getTokenSilently();

          try {
            const { body: dbUserData } = await Vue.http.get(process.env.VUE_APP_API_URL + '/users/me', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });

            const { body: companyData } = await Vue.http.get(process.env.VUE_APP_API_URL + '/users/me/company', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });

            const { body: permissionsData } = await Vue.http.get(process.env.VUE_APP_API_URL + '/users/me/permissions', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });

            this.settings = {
              ...(this.user ? this.user.settings : {}),
              ...(companyData ? companyData.settings : {})
            };

            this.company = companyData;
            this.socketsApiUrl = `${process.env.VUE_APP_SOCKETS_API_URL}?token=${token}`;

            this.permissions = [];

            // Set all core permissions
            permissionsData.forEach(p => {
              const isCompanyUser = p.code === 'company_user' || p.code === 'company_admin';

              if (isCompanyUser) {
                this.permissions.push({ code: 'company_user' }, { code: 'entity_user' }, { code: 'asset_user' }, { code: 'account_user' });
              } else if (p.linkedItems.some(i => i.type === 'entity')) {
                this.permissions.push({ code: 'entity_user' }, { code: 'asset_user' }, { code: 'account_user' });
              } else if (p.linkedItems.some(i => i.type === 'asset')) {
                this.permissions.push({ code: 'asset_user' }, { code: 'account_user' });
              } else if (p.linkedItems.some(i => i.type === 'account')) {
                this.permissions.push({ code: 'account_user' });
              }

              this.permissions.push(p);
            });

            this.permissions = _uniqBy(this.permissions, 'code');

            console.log('user permissions', this.permissions);

            // await initSockets(token, ['scraperRun']);

            Sentry.setUser({ email: this.user.email });

            console.log('user', dbUserData);

            Vue.mixpanel.identify(this.user.sub);

            Vue.mixpanel.people.set({
              $name: this.user.name,
              $email: this.user.email,
              Company: this.company.name,
              'Company ID': this.companyId,
              'Entity ID': this.entityId,
              Permissions: this.permissions.map(p => p.code),
              'Role ID': dbUserData.role?._id,
              'Role Name': dbUserData.role?.name,
              'API Access': !!dbUserData.apiKey

              // Add anything else about the user here
            });
          } catch (e) {
            this.auth0Client.logout();
          }
        }

        this.loading = false;
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      async loginWithKiosk(o) {
        this.loading = true;
        try {
          this.isAuthenticated = true;
          this.user = {
            email: 'kiosk@etainabl.com',
            name: o.id,
            kioskId: o.id
          };
        } catch (e) {
          this.error = e;
          return false;
        } finally {
          this.loading = false;
        }
      },
      async loginWithMachine(o) {
        this.loading = true;
        try {
          this.isAuthenticated = true;
          this.user = {
            email: 'machine@etainabl.com',
            name: o.name,
            kioskId: o.id
          };
        } catch (e) {
          this.error = e;
          return false;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        Sentry.setUser(null);
        Vue.mixpanel.reset();
        return this.auth0Client.logout(o);
      },
      machineUnauthorised() {
        this.isAuthenticated = false;
        this.user = {};
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(v, options) {
    // eslint-disable-next-line
    v.prototype.$auth = useAuth0(options);
  }
};
