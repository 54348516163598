<template>
  <div>
    <Spinner v-if="loading" class="pt-5" />

    <div v-else-if="error" class="alert alert-danger">
      {{ error }}
    </div>

    <div v-else>
      <TabTitle class="mb-4">
        <template>{{ benchmark.name }}</template>
        <template #sub-title>{{ benchmark.description }}</template>
      </TabTitle>

      <!-- Dropdown with past 5 years -->
      <div class="row">
        <div class="col-md-2">
          <FormGroup id="category" v-model="category" label="CRREM Category" :options="categoryOptions" type="select" alt />
        </div>
        <div class="col-md-2">
          <FormGroup
            id="source"
            v-model="source"
            label="Source"
            :options="[
              { label: 'Combined', value: 'combined' },
              { label: 'Invoice', value: 'invoice' },
              { label: 'Readings', value: 'reading' },
              { label: 'Custom', value: 'custom' },
              { label: 'HH Data', value: 'hh' }
            ]"
            type="select"
            alt
            @input="loadData(false)"
          />
        </div>
        <div class="col">
          <label for="category" class="">Assets</label>
          <div class="input-group">
            <!-- <FormItem
              id="filterType"
              v-model="filterType"
              :options="[
                { value: 'asset', label: 'Asset' }
                // { value: 'portfolio', label: 'Portfolio' }
              ]"
              type="select"
              alt
              style="max-width: 120px"
            /> -->
            <div class="input-group-append">
              <FormItem id="filter" v-model="filter" :options="typeOptions" type="select2" @input="loadData" />
            </div>
          </div>
        </div>
      </div>

      <apexchart class="mb-4" type="line" height="350" :options="pathwayChartOptions" :series="pathwaySeries" />

      <p class="font-w600 text-center">
        <i class="fa fa-exclamation-triangle text-warning"></i> The below table may be inaccurate if floor areas are not set for all your assets.
      </p>
      <table class="table">
        <thead>
          <tr>
            <th>Benchmark Categories</th>
            <th>Proportion</th>
            <th>Electricity (kWh/m<sup>2</sup>)</th>
            <th>Gas (kWh/m<sup>2</sup>)</th>
            <th>Total (kWh/m<sup>2</sup>)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cat in categoriesBreakdown" :key="cat.name">
            <td>{{ cat.name }}</td>
            <td>{{ cat.proportion.toFixed(2) }}%</td>
            <td>{{ cat.elecIntensity.toFixed(2) }}</td>
            <td>{{ cat.gasIntensity.toFixed(2) }}</td>
            <td>{{ cat.totalIntensity.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';
import TabTitle from '@/components/base/TabTitle';
import Spinner from '@/components/SpinnerLogo';
import { colours } from '@/lib/chartHelpers';

export default {
  name: 'BenchmarkCRREM',
  components: {
    FormGroup,
    FormItem,
    TabTitle,
    Spinner
  },
  data() {
    return {
      loading: true,
      category: 'Office',
      filterType: 'asset',
      filter: null,
      error: '',
      elecTotalConsumption: 0,
      gasTotalConsumption: 0,
      source: 'combined'
    };
  },
  computed: {
    ...mapGetters({
      benchmarkLoading: 'benchmark/loadingAction',
      benchmarks: 'benchmark/benchmarks',
      assets: 'asset/assets',
      assetGroups: 'assetGroup/assetGroups',
      analytics: 'analytics/analytics',
      accountTypes: 'analytics/accountTypes'
    }),
    benchmark() {
      return this.benchmarks.find(benchmark => benchmark.name === 'CRREM');
    },
    categoriesBreakdown() {
      const categories = [...this.benchmark.fields.find(c => c.code === 'category').data, 'N/A'];

      const populatedCategories = categories.map(cat => {
        const assetsInCategory = this.assets.filter(a => {
          const categorySet = (a.benchmarks || []).some(b => b.benchmarkId === this.benchmark._id && b.fieldCode === 'category');
          const category = (a.benchmarks || []).find(b => b.benchmarkId === this.benchmark._id && b.fieldCode === 'category' && b.fieldValue === cat);

          return categorySet ? category : cat === 'N/A';
        });

        const assetId = this.filter;
        const asset = this.assets.find(a => a._id === assetId);
        const benchmark = asset.benchmarks.find(b => b.fieldValue === cat);

        if (!benchmark) {
          return {
            name: cat === 'N/A' ? 'Unspecified (Office)' : cat,
            assets: assetsInCategory.length,
            proportion: (assetsInCategory.length / this.assets.length) * 100,
            elecConsumption: 0,
            gasConsumption: 0,
            totalConsumption: 0,
            elecIntensity: 0,
            gasIntensity: 0,
            totalIntensity: 0
          };
        }

        const elecConsumption = this.elecTotalConsumption || 0;
        const gasConsumption = this.gasTotalConsumption || 0;

        const floorArea = asset.floorAreaUnit === 'metric' && asset.floorArea > 1 ? asset.floorArea : asset.floorArea * 0.092903;

        const elecIntensity = elecConsumption / floorArea;
        const gasIntensity = gasConsumption / floorArea;

        return {
          name: cat === 'N/A' ? 'Unspecified (Office)' : cat,
          assets: assetsInCategory.length,
          proportion: (assetsInCategory.length / this.assets.length) * 100,
          elecConsumption: elecConsumption,
          gasConsumption: gasConsumption,
          totalConsumption: elecConsumption + gasConsumption,
          elecIntensity: elecIntensity,
          gasIntensity: gasIntensity,
          totalIntensity: elecIntensity + gasIntensity
        };
      });

      populatedCategories.sort((a, b) => b.proportion - a.proportion);

      return populatedCategories;
    },
    crremCategoriesBreakdownByYear() {
      return this.benchmark.data.map(data => {
        const updatedValues = {};
        const assetId = this.filter;
        const asset = this.assets.find(a => a._id === assetId);

        data.values
          .filter(v => v.scenario === '1.5')
          .forEach(value => {
            // if no benchmark set for asset, assume it's an office
            const benchmark =
              asset.benchmarks.length === 0 ? value.category === 'Office' : asset.benchmarks.find(b => b.fieldValue === value.category);
            if (!benchmark) {
              updatedValues[value.category] = {
                ...value,
                totalFloorArea: 0,
                elecConsumption: 0,
                gasConsumption: 0,
                totalConsumption: 0,
                elecIntensity: 0,
                gasIntensity: 0,
                totalIntensity: 0
              };
              return;
            }

            const yearElecConsumption = this.analytics[`electricity-consumption-${data.year}`]?.data?.[0]?.consumption;
            const yearGasConsumption = this.analytics[`gas-consumption-${data.year}`]?.data?.[0]?.consumption;

            const floorArea = asset.floorAreaUnit === 'metric' && asset.floorArea > 1 ? asset.floorArea : asset.floorArea * 0.092903;

            let elecConsumption = yearElecConsumption || 0;
            let gasConsumption = yearGasConsumption || 0;

            updatedValues[value.category] = {
              ...value,
              totalFloorArea: floorArea,
              elecConsumption: elecConsumption,
              gasConsumption: gasConsumption,
              totalConsumption: elecConsumption + gasConsumption,
              elecIntensity: elecConsumption / floorArea,
              gasIntensity: gasConsumption / floorArea,
              totalIntensity: (elecConsumption + gasConsumption) / floorArea
            };
          });

        return {
          ...data,
          values: updatedValues
        };
      });
    },
    pathwaySeries() {
      return [
        {
          name: 'Asset Carbon Intensity',
          type: 'column',
          data: this.crremCategoriesBreakdownByYear.map(data => data.values[this.category].totalIntensity)
        },
        {
          name: '1.5 Scenario',
          type: 'line',
          data: this.benchmark.data.map(data => data.values.find(v => v.category === this.category).energyIntensity)
        }
      ];
    },
    pathwayChartOptions() {
      return {
        chart: {
          type: 'line',
          height: 350,
          stacked: false
        },
        markers: {
          size: 1
        },
        stroke: {
          width: [0, 4]
        },
        yaxis: {
          decimalsInFloat: 0
        },
        xaxis: {
          categories: this.benchmark.data.map(data => data.year)
        },
        title: {
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 600
          }
        },
        colors: colours
      };
    },
    categoryOptions() {
      return this.benchmark.fields
        .find(c => c.code === 'category')
        .data.map(cat => ({
          label: cat,
          value: cat
        }));
    },
    typeOptions() {
      let options = [];

      if (this.filterType === 'asset') {
        options = this.assets.map(asset => ({
          label: asset.siteName,
          value: asset._id
        }));
      } else {
        options = this.assetGroups.map(assetGroup => ({
          label: assetGroup.name,
          value: assetGroup._id
        }));
      }

      // sort in alphabetical order
      options.sort((a, b) => a.label.localeCompare(b.label));

      return options;
    }
  },
  async mounted() {
    await Promise.all([
      this.getBenchmarks(),
      this.getAssets({ data: { params: { $select: 'siteName,benchmarks,floorArea,floorAreaUnit' } } }),
      this.getAssetGroups({ data: { params: { type: 'company' } } })
    ]);

    this.filter = this.typeOptions[0].value;

    if (this.assetGroups.length === 0) {
      this.error = 'No portfolio asset groups found. Please create a company-wide portfolio to view the pathway.';
      return;
    }

    await this.loadData();
  },
  methods: {
    ...mapActions({
      getBenchmarks: 'benchmark/list',
      getAssets: 'asset/list',
      getAnalytics: 'analytics/getAnalyticsV2',
      getAssetGroups: 'assetGroup/list',
      getAccountTypes: 'analytics/getAccountTypes'
    }),
    async fetchAnalytics({ accountType, params, year }) {
      const results = await this.getAnalytics({
        id: `${accountType}-consumption-${year}`,
        params
      });

      // error handling
      if (results.message) {
        return {
          error: results.message,
          accountType,
          year
        };
      }

      return results;
    },
    async loadData(isAssetChange = true) {
      this.loading = true;

      if (isAssetChange) await this.getAccountTypes({ data: { selectedId: this.filter, type: this.filterType } });
      const filteredAccountTypes = this.accountTypes.filter(type => ['electricity', 'gas'].includes(type));

      const params = {
        id: 'consumption',
        startDate: moment('01-01-2018').format('DD-MM-YYYY'),
        endDate: moment().format('YYYY-MM-DD'),
        dataType: 'asset',
        granularity: 'yearly',
        source: this.source
      };

      if (this.filterType === 'asset') {
        params.assetId = this.filter;
      } else {
        params.assetGroupId = this.filter;
      }

      const yearLength = moment().diff('2018', 'years') + 1;
      const years = Array.from({ length: yearLength }, (v, i) => moment('2018').year() + i).map(year => year.toString());

      // fetch analytics for each year
      const analyticsPromises = years.reduce((acc, year) => {
        const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
        const endDate = moment(year).endOf('year').format('YYYY-MM-DD');
        const analyticsParams = {
          ...params,
          startDate,
          endDate
        };

        // fetch analytics for each account type
        const analyticsResults = filteredAccountTypes.map(
          async accountType =>
            await this.fetchAnalytics({
              accountType,
              params: {
                ...analyticsParams,
                accountType
              },
              year
            })
        );

        return [...acc, ...analyticsResults];
      }, []);

      const results = await Promise.all(analyticsPromises);

      // error handling
      const failedRequests = results.filter(result => result.error);
      if (failedRequests.length > 0) {
        console.error('Failed analytics requests:', failedRequests);
        this.$toasted.error(`There has been error fetching data. Some data may be incomplete.`);
      }

      years.map(year => {
        this.elecTotalConsumption = this.elecTotalConsumption + this.analytics[`electricity-consumption-${year}`]?.consumption || 0;
        this.gasTotalConsumption = this.gasTotalConsumption + this.analytics[`gas-consumption-${year}`]?.consumption || 0;
      });

      this.loading = false;
    }
  }
};
</script>
